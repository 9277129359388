import {Component} from 'react';
import axios from "axios"
import approval from "../../img/approval.jpg"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import StockitemSelect from "../Select/StockitemSelect";
import style2 from "../../style2.css"

let source = axios.CancelToken.source();

class Stock_out_received extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            cushow: false,
            iscreate: true,

            stock_item_obj_load:false,
            stock_item_obj: null,
            modal_type: '',

            name_en:'',
            name_am:'',
            type:'',
            siunit:'',
            dep_name:'',
            quantity_requested:'',
            quantity_approved:'',
            requested_by:'',
            approved_requested_by:'',
            submitted_by:'',
            received_by:'',
            remark_requested:'',
            remark_approve_requested:'',
            remark_received:'',
            requested_date:'',
            approved_requested_date:'',
            submitted_date:'',
            received_date:'',

        }
    }

    componentDidMount() {
        this.fetcheddata()
    }
    print = (event,id) => {
        event.preventDefault();
        if (this.state.objsload) {
            let temp=this.state.objs.datas.filter(v=> {
                if(v.id==id)
                    return v;
            })
            if(temp.length>0) {
                this.setState({
                    name_en: temp[0].name_en,
                    name_am: temp[0].name_am,
                    type: temp[0].type,
                    siunit: temp[0].siunit,
                    dep_name: temp[0].dep_name,
                    quantity_requested: temp[0].quantity_requested,
                    quantity_approved: temp[0].quantity_approved,
                    remark_requested: temp[0].remark_requested,
                    remark_approve_requested: temp[0].remark_approve_requested,
                    remark_received: temp[0].remark_received,
                    requested_by: temp[0].requested_by,
                    submitted_by: temp[0].submitted_by,
                    approved_requested_by: temp[0].approved_requested_by,
                    received_by: temp[0].received_by,
                    requested_date: temp[0].requested_date,
                    approved_requested_date: temp[0].approved_requested_date,
                    received_date: temp[0].received_date,
                    submitted_date: temp[0].submitted_date,
                },()=>{
                    window.print()
                })
            }
        }
    }
    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            //modal
            cushow: false,
            iscreate: true,

            id: '-1',
            stock_item_id: '-1',
            quantity_requested: '',
            remark_requested: '',
            modal_type: '',
            stock_item_obj_load:false,
            stock_item_obj: null,
        })
        let baseurl = myconfig.get_stock_out_received;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data)
                this.setState({
                    objs: res.data,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    tabledata = () => {
        let retdata = null;
        if (this.state.objsload) {
            let count = 0;
            retdata = this.state.objs.datas.map(value => {
                count = count + 1
               /* let sbwt = null;
                let sbwa = null;
                if (this.state.objs.usermatrix.approval) {
                    sbwt =
                        (
                            <td>{value.stock_balance_with_transit}</td>

                        )
                }
                if (this.state.objs.usermatrix.approval) {
                    sbwa =
                        (
                            <td>{value.stock_balance_actcual}</td>
                        )
                }*/
                return (<tr>
                    <td>{count}</td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.siunit}</td>
                    <td>{value.dep_name}</td>
                    <td>{value.quantity_requested}</td>
                    <td>{value.quantity_approved}</td>
                    <td>{value.requested_by}</td>
                    <td>{value.approved_requested_by}</td>
                    <td>{value.submitted_by}</td>
                    <td>{value.received_by}</td>
                    <td>{value.requested_date}</td>
                    <td>{value.approved_requested_date}</td>
                    <td>{value.submitted_date}</td>
                    <td>{value.received_date}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.cat_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    render() {
        let header = null;
      /*  let sbwt = null;
        let sbwa = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.approval) {
                sbwt =
                    (
                        <th>Balance with Transit</th>
                    )
            }
            if (this.state.objs.usermatrix.approval) {
                sbwa =
                    (
                        <th>Balance Actual</th>
                    )
            }

        }*/
        return (
            <>
                <Nav type="stock_out_received"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock-out Received</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Stock-out Received</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        {header}
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Name(EN)</th>
                                                    <th>Name(AM)</th>
                                                    <th>Unit</th>
                                                    <th>Department</th>
                                                    <th>QTY Requested</th>
                                                    <th>QTY Approved</th>
                                                    <th>Requested By</th>
                                                    <th>Approved By</th>
                                                    <th>Submitted By</th>
                                                    <th>Received By</th>
                                                    <th>Requested Date</th>
                                                    <th>Approved Date</th>
                                                    <th>Submitted Date</th>
                                                    <th>Received Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                            <div className="row">
                                <div  id="section_to_print" className="col-md-6">
                                    <div className="card card-primary card-outline">
                                        <div className="card-body box-profile">
                                            <div className="text-center">
                                                <img className="profile-user-img img-fluid img-circle" src={approval}
                                                     alt="User profile picture"/>
                                            </div>
                                            <h3 className="profile-username text-center">{this.state.name_en}</h3>
                                            <p className="text-muted text-center">{this.state.name_am}</p>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Stock Type</b> <a
                                                    className="float-right">{this.state.type}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Stock SI Unit</b> <a
                                                    className="float-right">{this.state.siunit}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Department</b> <a
                                                    className="float-right">{this.state.dep_name}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Quantity Requested</b> <a
                                                    className="float-right">{this.state.quantity_requested}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Quantity Approved</b> <a
                                                    className="float-right">{this.state.quantity_approved}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Remark Requested</b> <a
                                                    className="float-right">{this.state.remark_requested}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Remark Req Approved</b> <a
                                                    className="float-right">{this.state.remark_approve_requested}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Remark Received</b> <a
                                                    className="float-right">{this.state.remark_received}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Request By</b> <a
                                                    className="float-right">{this.state.requested_by}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Approved Req By</b> <a
                                                    className="float-right">{this.state.approved_requested_by}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Submitted By</b> <a
                                                    className="float-right">{this.state.submitted_by}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Received By</b> <a
                                                    className="float-right">{this.state.requested_by}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Request Date</b> <a
                                                    className="float-right">{this.state.requested_date}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Request Approved Date</b> <a
                                                    className="float-right">{this.state.approved_requested_date}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Submitted Date</b> <a
                                                    className="float-right">{this.state.submitted_date}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Received Date</b> <a
                                                    className="float-right">{this.state.received_date}</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row" >
                                            <div className="col-12 px-3">
                                                <strong>Copyright © 2021-2023 <a href="#">Tecnostar SD</a>.</strong>
                                                All rights reserved.
                                                <div className="float-right d-none d-sm-inline-block">
                                                    <b>Version</b> 1.0.1
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Stock_out_received;
