import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Order extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            filtered:[],
            orderlog:[],


            tabledata:[],
            searchtable:[],
            searchstatus:[],
            paymentreadytables:[],
            paymenttableid:-1,
            objsload: false,
            id:-1,
            cushow:false,
            logshow:false,
            iscreate:true,
            txtsearch:'',

            menucatagory:[],
            menucatid:-1,
            menus:[],
            name_en:'',
            name_am:'',
            price:'',
            content:'',
            type:'',
            table_name:'',
            order_by:'',
            quantity:'',
            remark:'',
            status:'',
        }
    }
    componentDidMount() {
        this.fetcheddata()
        this.fetchsearchtable()
        this.fetchstatus()
        setInterval(() => {
            this.fetcheddata(true)
        }, 10000)
    }
    fetcheddata = (isauto=false) => {
        let baseurl='';
        let redirecttologin=myconfig.redirecttologin;
        if(!isauto){
            this.setState({
                objs: [],
                objsload: false,
                //modal
                cushow: false,
                iscreate: true,
                name_en: '',
                name_am: '',
                price: '',
                content: '',
                type: '',
                table_name: '',
                order_by: '',
                quantity: '',
                remark: '',
                status: '',
                txtsearch: '',
                selectedindex: -1,
                paymenttableid: -1,
                menucatid:-1,
                paymentreadytables: [],
            })
            baseurl = myconfig.getcatgorys;
            axios.post(baseurl, {
                sid:window.localStorage.getItem("mycafe_sid"),
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                      window.localStorage.setItem("mycafe_sid", null);
                      window.localStorage.setItem("mycafe_username", null);
                      window.localStorage.setItem("mycafe_name", null);
                      var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        menucatagory: res.data.datas,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        }
        baseurl = myconfig.getorders;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered: res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        baseurl = myconfig.gettableforfinance;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    paymentreadytables: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    combotable=()=> {
        let retdata = this.state.paymentreadytables.map(value => {
            return (<>
                <option value={value.id}>{value.table_name}</option>
            </>)
        })
        return <>{retdata}</>
    }
    combocat=()=> {
        let retdata = this.state.menucatagory.map(value => {
            return (<>
                <option value={value.id}>{value.cat_name}</option>
            </>)
        })
        return <>{retdata}</>
    }
    combomenu=()=> {
        let retdata = this.state.menus.map(value => {
            return (<>
                <option value={value.id}>{value.name_am}</option>
            </>)
        })
        return <>{retdata}</>
    }
    fetchtable = () =>{
        let baseurl = myconfig.gettablebyuser;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {

            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                var result=res.data.datas
                const tabledata=result.map((index,response) => ({
                    "value" : index.id,
                    "label" : index.table_name
                }));
                this.setState({
                    tabledata
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchsearchtable = () =>{
        let baseurl = myconfig.gettablebyuser;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {

            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                var result=res.data.datas
                const searchtable=result.map((index,response) => ({
                    "value" : index.id,
                    "label" : index.table_name
                }));
                this.setState({
                    searchtable
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchstatus = () =>{
        let baseurl = myconfig.getstatuitems;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {

            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data)
                var result=res.data.datas
                const searchstatus=result.map((index,response) => ({
                    "value" : index.status,
                    "label" : index.status
                }));
                this.setState({
                    searchstatus
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        this.fetchtable()
        if(iscreate) {
            this.setState({
                cushow: true,
                iscreate: iscreate,
                name_en:'',
                name_am:'',
                price:'',
                content:'',
                type:'',
                table_name:'',
                order_by:'',
                quantity:'',
                remark:'',
                status:'',
            })
        }
        else {
            let baseurl = myconfig.getorder;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid:window.localStorage.getItem("mycafe_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("mycafe_sid", null);
                    window.localStorage.setItem("mycafe_username", null);
                    window.localStorage.setItem("mycafe_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        console.log(res.data)
                        this.setState({
                            cushow: true,
                            id:id,
                            iscreate: iscreate,
                            name_en:res.data.data.name_en,
                            name_am:res.data.data.name_am,
                            price:res.data.data.price,
                            content:res.data.data.content,
                            type:res.data.data.type,
                            table_name:res.data.data.table_name,
                            order_by:res.data.data.order_by,
                            quantity:res.data.data.quantity,
                            remark:res.data.data.remark,
                            status:res.data.data.status,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    deleteorder = (event,id=-1) => {
        event.preventDefault()
        let baseurl = myconfig.deleteorder;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    showlog = (event,id=-1) => {
        event.preventDefault()
        let baseurl = myconfig.getorderlogs;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    logshow: true,
                    orderlog: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    hidelog =()=>{
        this.setState({
            logshow:false,
        })
    }
    modalcu=()=> {
        let display1=null;
        let display2=null;
        if(this.state.iscreate) {
            display1 = <>
                <div className="col-6 px-3">
                    <div className="form-group">
                        <label>Menu Catagory</label>
                        <select className="form-control" value={this.menucatid} onChange={this.inputmgtcat}>
                            <option value="-1">--Select Menu Category--</option>
                            {this.combocat()}
                        </select>
                    </div>
                </div>
                <div className="col-6 px-3">
                    <div className="form-group">
                        <label>Menu</label>
                        <select className="form-control" name="menu_id" value={this.menu_id} onChange={this.inputmgtmenu}>
                            <option value="-1">--Select Menu--</option>
                            {this.combomenu()}
                        </select>
                    </div>
                </div>
            </>
            display2 = <>
                <div className="col-6 px-3">
                    <div className="form-group">
                        <label>Table</label>
                        <Select name="table_name" onChange={this.ontableChange} options={this.state.tabledata}/>
                    </div>
                </div>
            </>
        }
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Order Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        {display1}
                                        <div className="col-6 px-3">
                                            <div className="form-group">
                                                <label>Name English</label>
                                                <input  className="form-control" disabled type="text" name="name_en" onChange={this.inputmgt} value={this.state.name_en}/>
                                            </div>
                                        </div>
                                        <div className="col-6 px-3">
                                            <div className="form-group">
                                                <label>Price</label>
                                                <input  className="form-control" disabled type="text" name="price" onChange={this.inputmgt} value={this.state.price}/>
                                            </div>
                                        </div>
                                        <div className="col-6 px-3">
                                            <div className="form-group">
                                                <label>Content</label>
                                                <textarea className="form-control" disabled rows="5"  name="content"  onChange={this.inputmgt} value={this.state.content}></textarea>
                                            </div>
                                        </div>
                                        {display2}
                                        <div className="col-6 px-3">
                                            <div className="form-group">
                                                <label>Quantity</label>
                                                <input  className="form-control" type="text" name="quantity" onChange={this.inputmgt} value={this.state.quantity}/>
                                            </div>
                                        </div>
                                        <div className="col-6 px-3">
                                            <div className="form-group">
                                                <label>Remark</label>
                                                <textarea className="form-control" rows="5"  name="remark"  onChange={this.inputmgt} value={this.state.remark}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    logdata=()=>{
        let retdata=null;
        let count = 0;
        //retdata = this.state.objs.datas.map
        //this.showcu(event,false,value.id)---for edit
        retdata = this.state.orderlog.map(value => {
            count=count+1
            return (<tr>
                <td>{count}</td>
                <td></td>
                <td>{value.action}</td>
                <td>{value.action_time}</td>
            </tr>)
        })
        return (<>{retdata}</>)
    }
    logcu=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.logshow}
                onHide={this.hidelog}>
                <Modal.Header><h4>Order Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Action By</th>
                                                    <th>Action</th>
                                                    <th>Action Time</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.logdata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-danger" onClick={this.hidelog}> Okay </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updateorder;
        let redirecttologin = myconfig.redirecttologin;
        if (this.state.iscreate)
            baseurl = myconfig.createorder;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            menu_id: this.state.menu_id,
            table_id: this.state.table_id,
            quantity: this.state.quantity,
            remark: this.state.remark,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a> |
                        <a href="#" onClick={(event => {this.deleteorder(event,value.id)})}>Delete</a> |
                        <a href="" onClick={(event => {this.showlog(event,value.id)})}>Log</a></td>
                    <td>{value.name_am}</td>
                    <td>{value.name_en}</td>
                    <td>{value.table_name}</td>
                    <td>{value.price}</td>
                    <td>{value.order_by}</td>
                    <td>{value.quantity}</td>
                    <td>{value.remark}</td>
                    <td>{value.status}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgtcat = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            menus:[],
            name_en:'',
            name_am:'',
            price:'',
            content:'',
            type:'',
        })
        if(value!="-1"){
            let baseurl = myconfig.getmenucat_for_order;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid:window.localStorage.getItem("mycafe_sid"),
                catagories_id:value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("mycafe_sid", null);
                    window.localStorage.setItem("mycafe_username", null);
                    window.localStorage.setItem("mycafe_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        menus:res.data.datas,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    inputmgtmenu = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            name_en:'',
            name_am:'',
            price:'',
            content:'',
            type:'',
        })
        if(value!="-1"){
            let temp=this.state.menus.find(v=>{
                if(v.id==value)
                    return value
            })
            if(temp!==undefined){
                this.setState({
                    name_en:temp.name_en,
                    name_am:temp.name_am,
                    price:temp.price,
                    content:temp.content,
                    type:temp.type,
                })
            }
        }

    }

    onmenuChange = (event) =>{
        this.setState({
            menu_id: event.value,
        })
        this.getmenudata()
    }
    tofinance = (event) =>{
        event.preventDefault()
        if(this.state.paymenttableid!=-1){
            let baseurl = myconfig.tofinance;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid:window.localStorage.getItem("mycafe_sid"),
                table_id:this.state.paymenttableid,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("mycafe_sid", null);
                    window.localStorage.setItem("mycafe_username", null);
                    window.localStorage.setItem("mycafe_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(value => {
                        this.fetcheddata()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    ontableChange = (event) =>{
        this.setState({
            table_id: event.value,
        })
    }
    getmenudata =()=>{
        let baseurl = myconfig.getmenu;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
            id: 1,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        name_en:res.data.data.name_en,
                        price:res.data.data.price,
                        content:res.data.data.content,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event,iselect) => {
        if(iselect){
            var name = event.name;
            var value = event.value.toString();
        }else{
            event.preventDefault();
            var name = event.target.name;
            var value = event.target.value;
        }
        console.log(event.target)
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.status.toUpperCase().includes(value.toUpperCase())||
                    v.table_name.toUpperCase().includes(value.toUpperCase())||
                    v.name_en.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    render() {
        return (
            <>
                <Nav type="order"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Order Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Order</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>To Finance</label>
                                                <select  className="form-control"  onChange={this.inputmgt} name="paymenttableid" value={this.state.paymenttableid}>
                                                    <option value="-1">--Select Table--</option>
                                                    {this.combotable()}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <button className="form-group btn btn-outline-primary btn-block" onClick={this.tofinance}>To Finance</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right" value={this.state.txtsearch} onChange={(event => {this.inputsearch(event,false)})} placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <div className="card-tools" style={{width: '150px'}}>
                                                <Select name="status" onChange={(event => {this.inputsearch(event,true)})} options={this.state.searchstatus} />

                                            </div>
                                        </div>
                                        <div className="card-header">

                                            <div className="card-tools" style={{width: '150px'}}>
                                                <Select name="table" onChange={(event => {this.inputsearch(event,true)})} options={this.state.searchtable} />

                                            </div>
                                            <div className="card-tools" style={{width: '150px',marginRight: 10}}>
                                                <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New Order</button>

                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Name(EN)</th>
                                                    <th>Name(AM)</th>
                                                    <th>Table Name</th>
                                                    <th>Price</th>
                                                    <th>Order By</th>
                                                    <th>Quantity</th>
                                                    <th>Remark</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
                {this.logcu()}
            </>
        )
    }
}

export default Order;
