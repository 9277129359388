import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Welcome from "./Componet/Welcome";
import SIUnit from "./Componet/SIUnit";
import Department from "./Componet/Department";
import Employee from "./Componet/Employee";
import Stockitem from "./Componet/Stockitem";
import StockIN from "./Componet/StockIN";
import StockOUT from "./Componet/StockOUT";
import StockWastage from "./Componet/StockWastage";
import CatByMenu from "./Componet/CatByMenu";
import RStockIN from "./Componet/Report/RStockIN";
import RStockOut from "./Componet/Report/RStockOut";
import RStockWastage from "./Componet/Report/RStockWastage";
import RStockItem from "./Componet/Report/RStockItem";
import User from "./Componet/User";
import ObjUser from "./Componet/ObjUser";
import UserAccess1 from "./Componet/UserAccess1";
import UserAccess2 from "./Componet/UserAccess2";
import Login from "./Componet/Login";
import MenuType from "./Componet/Menu/MenuType";
import MenuCatagory from "./Componet/Menu/MenuCatagory";
import Catagory from "./Componet/Menu/Category";
import Menu from "./Componet/Menu/Menu";
import Order from "./Componet/Operation/Order";
import Table from "./Componet/Operation/Table";
import Chefe from "./Componet/Operation/Chefe";
import Finance from "./Componet/Operation/Finance";
import Beverage from "./Componet/Operation/Beverage";
import UserTable from "./Componet/UserTable";
import ROrder from "./Componet/Report/ROrder";
import RFinance from "./Componet/Report/RFinance";
import Purchase_req from "./Componet/Purchase/Purchase_req";
import Purchase_req_approved from "./Componet/Purchase/Purchase_req_approved";
import Purchase_req_reject from "./Componet/Purchase/Purchase_req_reject";
import Purchase_purchased from "./Componet/Purchase/Purchase_purchased";
import Purchase_not_purchased from "./Componet/Purchase/Purchase_not_purchased";
import Purchase_purchased_approved from "./Componet/Purchase/Purchase_purchased_approved";
import Purchase_received from "./Componet/Purchase/Purchase_received";
import Stock_out_req from "./Componet/Stock_out/Stock_out_req";
import Stock_out_req_approved from "./Componet/Stock_out/Stock_out_req_approved";
import Stock_out_req_reject from "./Componet/Stock_out/Stock_out_req_reject";
import Stock_out_submitted from "./Componet/Stock_out/Stock_out_submitted";
import Stock_out_received from "./Componet/Stock_out/Stock_out_received";
import Stock_wastage_manage from "./Componet/Stock_wastage/Stock_wastage_manage";
import Stock_wastage_req from "./Componet/Stock_wastage/Stock_wastage_req";
import Stock_wastage_req_approved from "./Componet/Stock_wastage/Stock_wastage_req_approved";
import Stock_wastage_req_reject from "./Componet/Stock_wastage/Stock_wastage_req_reject";
import R_daily_sales from "./Componet/Report/R_daily_sales";
import R_daily_sales_2 from "./Componet/Report/R_daily_sales_2";
import R_order_for_waiter from "./Componet/Report/R_order_for_waiter";
import R_order_for_chafe from "./Componet/Report/R_order_for_chafe";
import R_order_for_barman from "./Componet/Report/R_order_for_barman";
import R_order_by from "./Componet/Report/R_order_by";
import R_served_by from "./Componet/Report/R_served_by";
import Usertime from "./Componet/Usertime";
import Login_PIN from "./Componet/Login_PIN";
import Report_stock_balance from "./Componet/Report/Report_stock_balance";
import Rstockinsum from "./Componet/Report/Rstockinsum";
import Rstockoutsum from "./Componet/Report/Rstockoutsum";
import D_daily_activity from "./Componet/Dashboard/D_daily_activity";
import D_yesterday_activity from "./Componet/Dashboard/D_yesterday_activity";
import R_waiting_time from "./Componet/Report/R_waiting_time";
import R_notcom_order from "./Componet/Report/R_notcom_order";
import R_removed_order from "./Componet/Report/R_removed_order";
import Ordersforscreen from "./Componet/Operation/Ordersforscreen";

function App() {
    return (
        <>
            <BrowserRouter basename='/'>
                <Switch>
                    <Route path="/" exact component={Welcome}/>
                    <Route path="/siunit" exact component={SIUnit}/>
                    <Route path="/department" exact component={Department}/>
                    <Route path="/employee" exact component={Employee}/>
                    <Route path="/stockitem" exact component={Stockitem}/>
                    <Route path="/stockin" exact component={StockIN}/>
                    <Route path="/stockout" exact component={StockOUT}/>
                    <Route path="/stockwastage" exact component={StockWastage}/>
                    <Route path="/catbymenu" exact component={CatByMenu}/>
                    <Route path="/rstockin" exact component={RStockIN}/>
                    <Route path="/rstockout" exact component={RStockOut}/>
                    <Route path="/rstockwastage" exact component={RStockWastage}/>
                    <Route path="/rstockitem" exact component={RStockItem}/>
                    <Route path="/rorder" exact component={ROrder}/>
                    <Route path="/rfinance" exact component={RFinance}/>
                    <Route path="/user" exact component={User}/>
                    <Route path="/objuser" exact component={ObjUser}/>
                    <Route path="/useraccess1" exact component={UserAccess1}/>
                    <Route path="/useraccess2" exact component={UserAccess2}/>
                    <Route path="/usertable" exact component={UserTable}/>
                    <Route path="/login" exact component={Login}/>

                    <Route path="/menutype" exact component={MenuType}/>
                    <Route path="/catagory" exact component={Catagory}/>
                    <Route path="/menu" exact component={Menu}/>
                    <Route path="/menumatagory" exact component={MenuCatagory}/>
                    <Route path="/table" exact component={Table}/>
                    <Route path="/order" exact component={Order}/>
                    <Route path="/chefe" exact component={Chefe}/>
                    <Route path="/beverage" exact component={Beverage}/>
                    <Route path="/finance" exact component={Finance}/>


                    <Route path="/purchase_req" exact component={Purchase_req}/>
                    <Route path="/purchase_req_approved" exact component={Purchase_req_approved}/>
                    <Route path="/purchase_req_reject" exact component={Purchase_req_reject}/>
                    <Route path="/purchase_purchased" exact component={Purchase_purchased}/>
                    <Route path="/purchase_not_purchased" exact component={Purchase_not_purchased}/>
                    <Route path="/purchase_purchased_approved" exact component={Purchase_purchased_approved}/>
                    <Route path="/purchase_received" exact component={Purchase_received}/>


                    <Route path="/stock_out_req" exact component={Stock_out_req}/>
                    <Route path="/stock_out_req_approved" exact component={Stock_out_req_approved}/>
                    <Route path="/stock_out_req_reject" exact component={Stock_out_req_reject}/>
                    <Route path="/stock_out_submitted" exact component={Stock_out_submitted}/>
                    <Route path="/stock_out_received" exact component={Stock_out_received}/>
                    <Route path="/stock_wastage_manage" exact component={Stock_wastage_manage}/>
                    <Route path="/stock_wastage_req" exact component={Stock_wastage_req}/>
                    <Route path="/stock_wastage_req_approved" exact component={Stock_wastage_req_approved}/>
                    <Route path="/stock_wastage_req_reject" exact component={Stock_wastage_req_reject}/>

                    <Route path="/r_daily_sales" exact component={R_daily_sales}/>
                    <Route path="/r_daily_sales_2" exact component={R_daily_sales_2}/>
                    <Route path="/r_order_for_waiter" exact component={R_order_for_waiter}/>
                    <Route path="/r_order_for_chafe" exact component={R_order_for_chafe}/>
                    <Route path="/r_order_for_barman" exact component={R_order_for_barman}/>
                    <Route path="/r_order_by" exact component={R_order_by}/>
                    <Route path="/r_served_by" exact component={R_served_by}/>
                    <Route path="/usertime" exact component={Usertime}/>
                    <Route path="/auth_2" exact component={Login_PIN}/>
                    <Route path="/report_stock_balance" exact component={Report_stock_balance}/>
                    <Route path="/rstockinsum" exact component={Rstockinsum}/>
                    <Route path="/rstockoutsum" exact component={Rstockoutsum}/>
                    <Route path="/d_daily_activity" exact component={D_daily_activity}/>
                    <Route path="/d_yesterday_activity" exact component={D_yesterday_activity}/>
                    <Route path="/r_waiting_time" exact component={R_waiting_time}/>
                    <Route path="/r_notcom_order" exact component={R_notcom_order}/>
                    <Route path="/r_removed_order" exact component={R_removed_order}/>
                    <Route path="/ordersforscreen" exact component={Ordersforscreen}/>
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;