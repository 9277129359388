import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';
import Select from "react-select";

let source = axios.CancelToken.source();

class UserTable extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            obj: [],
            objload: false,
            //modal
            cushow:false,
            id:'',
            username:'',
            usertabels:[],
            filteredusertabels:[],
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let baseurl = myconfig.getusers;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }


    showcu = (event,username, id=-1) => {
        event.preventDefault()
        this.setState({
            id: id,
            username: username,
        }, () => this.fetchtoogledata())
    }
    fetchtoogledata=()=>{
            let baseurl = myconfig.getusertable;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            user_id: this.state.id,
        }, {cancelToken: source.token}).then(res => {
            // console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                    this.setState({
                        usertabels:res.data.datas,
                        filteredusertabels:res.data.datas,
                        cushow: true,
                    })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    toggleuser = (event,table_id=-1,st) => {
        event.preventDefault()
        let isinculde = 0;
        if (st == "Include")
            isinculde = 1;
        let baseurl = myconfig.toggleusertable;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            table_id: table_id,
            user_id: this.state.id,
            isinculde: isinculde,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(() => {
                    this.fetchtoogledata()
                })
            }
            //
            //}
            //}
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    usertabledata=()=>{
        let retdata=null;
        let count = 0;
        //retdata = this.state.objs.datas.map
        //this.showcu(event,false,value.id)---for edit
        retdata = this.state.filteredusertabels.map(value => {
            count=count+1
            let st=value.status=="Not-Include"?"Include":"Not-Include";
            return (<tr>
                <td><a href="" onClick={(event => {this.toggleuser(event,value.table_id,st)})} >{st}</a></td>
                <td>{value.table_name}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>{retdata}</>)
    }
    modalcu=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>User Table ({this.state.username}) Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-tools">
                                        <div className="input-group input-group-sm" style={{width: '150px'}}>
                                            <input type="text" name="txtsearch"
                                                   className="form-control float-right" value={this.state.txtsearch} onChange={(event => {this.inputsearch(event,false)})} placeholder="Search"/>
                                            <div className="input-group-append">
                                                <button type="submit" className="btn btn-default">
                                                    <i className="fas fa-search"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                            <table className="table table-head text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Table Name</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.usertabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updatecategory;
        if (this.state.iscreate)
            baseurl = myconfig.createcategory;
        axios.post(baseurl, {
            id: this.state.id,
            catagory: this.state.catagory,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count=count+1
                let isactive="Yes";
                let isadmin="No";
                if(value.isactive=="0")
                    isactive="No";
                if(value.isadmin=="1")
                    isadmin="Yes";

                return (<tr>
                    <td>{count}</td>
                    <td>
                        <a href="" onClick={(event => {this.showcu(event,value.username,value.id)})}>Manage Table Access</a>
                    </td>
                    <td>{value.username}</td>
                    <td>{value.name}</td>
                    <td>{value.email}</td>
                    <td>{isadmin}</td>
                    <td>{isactive}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    render() {
        return (
            <>
                <Nav type="usertable"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">User Access's Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">User Access</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Username</th>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Is Admin</th>
                                                    <th>Is Active</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default UserTable;
