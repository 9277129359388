import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import style2 from "../../style2.css"
let source = axios.CancelToken.source();

class RStockOut extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            id: -1,
            st: '',
            ed: '',
        }
    }
    componentDidMount() {
    }
    fetcheddata = () => {
        let baseurl = myconfig.report_stock_out;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            st:this.state.st,
            ed:this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    tabledata=()=> {
        let retdata = null;
        if (this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count = count + 1
                return (<tr>
                    <td>{count}</td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.siunit}</td>
                    <td>{value.dep_name}</td>
                    <td>{value.requested_date}</td>
                    <td>{value.approved_requested_date}</td>
                    <td>{value.received_date}</td>
                    <td>{value.requested_by}</td>
                    <td>{value.approved_requested_by}</td>
                    <td>{value.requested_by}</td>
                    <td>{value.quantity_approved}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btngetreport = (event) => {
        event.preventDefault();
        this.fetcheddata()
    }
    btnprint = (event) => {
        event.preventDefault();
        window.print()
    }
    render() {
        return (
            <>
                <Nav type="rstockout"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock OUT Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report Stock OUT</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Report Criteria</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <input  className="form-control" type="date" name="st" onChange={this.inputmgt} value={this.state.st}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <input  className="form-control" type="date" name="ed" onChange={this.inputmgt} value={this.state.ed}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <button  className="btn btn-outline-primary form-control" onClick={this.btngetreport}> Get Report</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Report Data</h3>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <div className="px-3 py-1 text-left"><a href="#" onClick={this.btnprint}>Print</a></div>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Name(EN) </th>
                                                    <th>Name(AM) </th>
                                                    <th>Unit </th>
                                                    <th>For</th>
                                                    <th>Requested Date</th>
                                                    <th>Approved Date</th>
                                                    <th>Received Date</th>
                                                    <th>Requested By</th>
                                                    <th>Approved By</th>
                                                    <th>Received By</th>
                                                    <th>Quantity</th>
                                                </tr>
                                                </thead>
                                                <tbody>{this.tabledata()}</tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                <table id="section_to_print_2" className="table">
                                    <thead>
                                    <tr>
                                        <th width="5.5%">#</th>
                                        <th>Name(EN) </th>
                                        <th>Name(AM) </th>
                                        <th>Unit </th>
                                        <th>For</th>
                                        <th>Requested Date</th>
                                        <th>Approved Date</th>
                                        <th>Received Date</th>
                                        <th>Requested By</th>
                                        <th>Approved By</th>
                                        <th>Received By</th>
                                        <th>Quantity</th>
                                    </tr>
                                    </thead>
                                    <tbody>{this.tabledata()}</tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default RStockOut;
