import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import {Modal} from "react-bootstrap";
let source = axios.CancelToken.source();

class D_daily_activity extends Component {

    constructor() {
        super();
        this.state = {
            menu_types:[],

            menu_return:[],
            menu_return_filtered:[],

            store_items_in:[],
            store_items_out:[],
            total_sale: 0,
            total_stock_in: 0,
            total_stock_out: 0,
            type:'',
            cushow:false,
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        let baseurl = myconfig.d_daily_activity;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            menu_types:[],
            menu_return:[],
            menu_return_filtered:[],
            total_sale: 0,
            store_items_in:[],
            store_items_out:[],
            type:'',
            cushow:false,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
               /* Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })*/
                this.setState({
                    obj: res.data,
                    objload: false,
                    menu_types:res.data.menu_types,
                    total_sale: res.data.total_sale,
                    menu_return: res.data.menu_return,
                    total_stock_in: res.data.store_items_in.length,
                    total_stock_out: res.data.store_items_out.length,
                    store_items_in: res.data.store_items_in,
                    store_items_out: res.data.store_items_out,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    sale_detail = () => {
        let data=this.state.menu_types.map(value=> {
            return (
                <li className="nav-item">
                    <a href="" onClick={(event => this.showcu(event, value.id, value.type))} className="nav-link">
                        {value.type}<span
                        className="float-right badge bg-primary">$ {value.total} </span>
                    </a>
                </li>)
        })
        return (
            <div className="col-md-4">
                <div className="card card-widget widget-user-2 table-responsive p-0"
                     style={{height: '400px'}}>
                    <div className="widget-user-header bg-info">
                        <div className="widget-user-image">
                            <img className="img-circle elevation-2"
                                 src="../dist/img/user7-128x128.png" alt="User Avatar"/>
                        </div>
                        <h3 className="widget-user-username">Sales</h3>
                        <h5 className="widget-user-desc">Sale By Categories</h5>
                    </div>
                    <div className="card-footer p-0">
                        <ul className="nav flex-column">
                            {data}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    stock_in_detail = () => {
        let data=this.state.store_items_in.map(value=> {
            return (
                <li className="nav-item">
                    <a className="nav-link">
                        {value.name_en}<span
                        className="float-right badge bg-primary">{value.quantiy}</span>
                    </a>
                </li>)
        })
        return (
            <div className="col-md-4">
                <div className="card card-widget widget-user-2 table-responsive p-0"
                     style={{height: '400px'}}>
                    <div className="widget-user-header bg-warning">
                        <div className="widget-user-image">
                            <img className="img-circle elevation-2"
                                 src="../dist/img/user8-128x128.png" alt="User Avatar"/>
                        </div>
                        <h3 className="widget-user-username">Stocks</h3>
                        <h5 className="widget-user-desc">IN</h5>
                    </div>
                    <div className="card-footer p-0">
                        <ul className="nav flex-column">
                            {data}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    stock_out_detail = () => {
        let data=this.state.store_items_out.map(value=> {
            return (
                <li className="nav-item">
                    <a className="nav-link">
                        {value.name_en}<span
                        className="float-right badge bg-primary">{value.quantiy}</span>
                    </a>
                </li>)
        })
        return (
            <div className="col-md-4">
                <div className="card card-widget widget-user-2 table-responsive p-0"
                     style={{height: '400px'}}>
                    <div className="widget-user-header bg-danger">
                        <div className="widget-user-image">
                            <img className="img-circle elevation-2"
                                 src="../dist/img/user9-128x128.png" alt="User Avatar"/>
                        </div>
                        <h3 className="widget-user-username">Stocks</h3>
                        <h5 className="widget-user-desc">OUT</h5>
                    </div>
                    <div className="card-footer p-0">
                        <ul className="nav flex-column">
                            {data}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event,id=-1,type="Unknown") => {
        event.preventDefault()
        let temp=this.state.menu_return.filter(value => {
            if(value.type_id==id)
                return value
        })
        this.setState({
            cushow: true,
            type: type,
            menu_return_filtered: temp,
        })
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        let count=0;
        let tabledata = this.state.menu_return_filtered.map(value => {
            count = count + 1
            return (<tr>
                <td>{count}</td>
                <td>{value.name_en}</td>
                <td>{value.name_am}</td>
                <td>{value.count}</td>
                <td>{value.price}</td>
                <td>{value.total}</td>
            </tr>)
        })
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>{this.state.type}</h4></Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Sales List</h3>
                        </div>
                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                            <table className="table table-head-fixed text-nowrap">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Menu Eng</th>
                                    <th>Menu Amh</th>
                                    <th>QTY</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>{tabledata}</tbody>
                            </table>
                        </div>
                        {/* /.card-body */}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        return (
            <>
                <Nav type="d_daily_activity"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Today Activity</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Activity</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>$ {this.state.total_sale}</h3>
                                            <p>Sales</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag"/>
                                        </div>
                                        <a className="small-box-footer">Today</a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{this.state.total_stock_in}</h3>
                                            <p>Stock-In</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-stats-bars"/>
                                        </div>
                                        <a href="#" className="small-box-footer">Today</a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <h3>{this.state.total_stock_out}</h3>
                                            <p>Stock-Out</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-pie-graph"/>
                                        </div>
                                        <a className="small-box-footer">Today</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.sale_detail()}
                                {this.stock_in_detail()}
                                {this.stock_out_detail()}
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default D_daily_activity;
