import {Component} from 'react';
import axios from "axios"
import approval from "../../img/approval.jpg"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import StockitemSelect from "../Select/StockitemSelect";

let source = axios.CancelToken.source();

class Stock_out_req_approved extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            cushow: false,
            iscreate: true,

            id: '-1',
            stock_item_id: '-1',
            quantity_requested: '',
            remark_requested: '',
            stock_item_obj_load:false,
            stock_item_obj: null,
            modal_type: '',

            quantity_purchase:'',
            remark_purchase:'',

            remark_approve_requested:'',
            cushow2:false,
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            //modal
            cushow: false,
            iscreate: true,

            id: '-1',
            stock_item_id: '-1',
            quantity_requested: '',
            remark_requested: '',
            modal_type: '',
            stock_item_obj_load:false,
            stock_item_obj: null,
        })
        let baseurl = myconfig.get_stock_out_approved;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data)
                this.setState({
                    objs: res.data,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event, modal_type, id = -1) => {
        event.preventDefault()
        let baseurl = myconfig.get_single_stock_out_approved;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            id:id,
            quantity_purchase: '',
            remark_purchase: '',
            stock_item_obj_load:false,
        })
        axios.post(baseurl, {
            id: id,
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        cushow: true,
                        id:id,
                        modal_type: modal_type,
                        stock_item_obj_load:true,
                        stock_item_obj:res.data.data,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });


    }
    modalcu = () => {
        if (this.state.stock_item_obj_load) {
            let header = "Submitted";
            let buttonexe = <a href="#" onClick={event => this.submitcu(event,1)}
                               className="btn btn-primary btn-block"><b>Submitted</b></a>;
            let label_remark_purchase="Remark Submitted";
            if(this.state.modal_type=="notpurchased"){
                header = "Not-purchased";
                buttonexe = <a href="#" onClick={event => this.submitcu(event,0)}
                                   className="btn btn-danger btn-block"><b>Not-purchased</b></a>;
                label_remark_purchase="Remark Not-purchased";
            }
            return (<Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.cushow}
                    onHide={this.hidecu}>
                    <Modal.Header><h4>{header}</h4></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <img className="profile-user-img img-fluid img-circle" src={approval}
                                                 alt="User profile picture"/>
                                        </div>
                                        <h3 className="profile-username text-center">{this.state.stock_item_obj.name_en}</h3>
                                        <p className="text-muted text-center">{this.state.stock_item_obj.name_am}</p>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            <li className="list-group-item">
                                                <b>Stock Type</b> <a
                                                className="float-right">{this.state.stock_item_obj.type}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Stock SI Unit</b> <a
                                                className="float-right">{this.state.stock_item_obj.siunit}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Department</b> <a
                                                className="float-right">{this.state.stock_item_obj.dep_name}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Quantity Requested</b> <a
                                                className="float-right">{this.state.stock_item_obj.quantity_requested}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Quantity Approved</b> <a
                                                className="float-right">{this.state.stock_item_obj.quantity_approved}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Request By</b> <a
                                                className="float-right">{this.state.stock_item_obj.requested_by}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Approved By</b> <a
                                                className="float-right">{this.state.stock_item_obj.approved_requested_by}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Request Date</b> <a
                                                className="float-right">{this.state.stock_item_obj.requested_date}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Approved Date</b> <a
                                                className="float-right">{this.state.stock_item_obj.approved_requested_date}</a>
                                            </li>
                                        </ul>
                                        <div className="col-12 px-3">
                                            <div className="form-group">

                                                <label>{label_remark_purchase}</label>
                                                <textarea rows="5"
                                                          className="form-control" type="text" name="remark_purchase"
                                                          onChange={this.inputmgt}>
            {this.state.remark_purchase}
            </textarea>
                                            </div>
                                        </div>
                                        {buttonexe}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }
    }
    hidecu = () => {
        this.setState({
            cushow: false,
        })
    }

    submitcu = (event,isapproved) => {
        event.preventDefault()
        let baseurl = myconfig.submitted_stockout_request;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            isapproved: isapproved,
            quantity_purchase: this.state.quantity_purchase,
            remark_purchase: this.state.remark_purchase,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    showcu2 = (event, modal_type, id = -1) => {
        event.preventDefault()
        let baseurl = myconfig.get_single_stock_out_approved;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            id:id,
            quantity_purchase: '',
            remark_purchase: '',
            stock_item_obj_load:false,
        })
        axios.post(baseurl, {
            id: id,
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        cushow2: true,
                        id:id,
                        modal_type: modal_type,
                        stock_item_obj_load:true,
                        stock_item_obj:res.data.data,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });


    }
    modalcu2 = () => {
        if (this.state.stock_item_obj_load) {
            return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow2}
                onHide={this.hidecu2}>
                <Modal.Header><h4>Reject Page</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        <img className="profile-user-img img-fluid img-circle" src={approval}
                                             alt="User profile picture"/>
                                    </div>
                                    <h3 className="profile-username text-center">{this.state.stock_item_obj.name_en}</h3>
                                    <p className="text-muted text-center">{this.state.stock_item_obj.name_am}</p>
                                    <p className="text-muted text-center">{this.state.stock_item_obj.siunit}</p>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        <li className="list-group-item">
                                            <b>Stock Type</b> <a
                                            className="float-right">{this.state.stock_item_obj.type}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Stock SI Unit</b> <a
                                            className="float-right">{this.state.stock_item_obj.siunit}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Department</b> <a
                                            className="float-right">{this.state.stock_item_obj.dep_name}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Quantity Requested</b> <a
                                            className="float-right">{this.state.stock_item_obj.quantity_requested}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Quantity Approved</b> <a
                                            className="float-right">{this.state.stock_item_obj.quantity_approved}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Request By</b> <a
                                            className="float-right">{this.state.stock_item_obj.requested_by}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Approved By</b> <a
                                            className="float-right">{this.state.stock_item_obj.approved_requested_by}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Request Date</b> <a
                                            className="float-right">{this.state.stock_item_obj.requested_date}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Approved Date</b> <a
                                            className="float-right">{this.state.stock_item_obj.approved_requested_date}</a>
                                        </li>
                                    </ul>
                                    <div className="col-12 px-3">
                                        <div className="form-group">
                                            <label>Quantity Approved</label>
                                            <input disabled={true} className="form-control"
                                                   type="text" name="quantity_approved" onChange={this.inputmgt}
                                                   value={this.state.stock_item_obj.quantity_approved}/>
                                        </div>
                                    </div>
                                    <div className="col-12 px-3">
                                        <div className="form-group">
                                            <label>Remark Req Reject</label>
                                            <textarea rows="5"
                                                      className="form-control" type="text"
                                                      name="remark_approve_requested"
                                                      onChange={this.inputmgt}>
                                                    {this.state.remark_approve_requested}
                                            </textarea>
                                        </div>
                                    </div>
                                    <a href="#" onClick={event => this.submitcu2(event, 0)}
                                       className="btn btn-danger btn-block"><b>Reject</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>)
        }
    }
    hidecu2 = () => {
        this.setState({
            cushow2: false,
        })
    }
    submitcu2 = (event,isapproved) => {
        event.preventDefault()
        let baseurl = myconfig.approve_reject_stockout_request;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            remark_approve_requested: this.state.remark_approve_requested,
            isapproved: isapproved,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata = () => {
        let retdata = null;
        if (this.state.objsload) {
            let count = 0;
            retdata = this.state.objs.datas.map(value => {
                count = count + 1
                let rej = null;
                let summit = null;
                let sbwt = null;
                let sbwa = null;
                if (this.state.objs.usermatrix.approval) {
                    sbwt =
                        (
                            <td>{value.stock_balance_with_transit}</td>

                        )
                }
                if (this.state.objs.usermatrix.approval) {
                    sbwa =
                        (
                            <td>{value.stock_balance_actcual}</td>
                        )
                }
                if (this.state.objs.usermatrix.update) {
                    summit =
                        (
                            <td><a href='' onClick={(event => {
                                this.showcu(event, "purchased", value.id)
                            })}>Submit</a>
                            </td>
                        )
                }
                if (this.state.objs.usermatrix.approval) {
                    rej =
                        (
                            <td><a href='' onClick={(event => {
                                this.showcu2(event, "reject", value.id)
                            })}>Reject</a>
                            </td>
                        )
                }
                return (<tr>
                    <td>{count}</td>
                    {summit}
                    {rej}
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.siunit}</td>
                    <td>{value.dep_name}</td>
                    <td>{value.quantity_requested}</td>
                    <td>{value.quantity_approved}</td>
                    {sbwt}
                    {sbwa}
                    <td>{value.requested_by}</td>
                    <td>{value.approved_requested_by}</td>
                    <td>{value.requested_date}</td>
                    <td>{value.approved_requested_date}</td>
                    <td>{value.remark_approve_requested}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.cat_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    render() {
        let header = null;
        let rej = null;
        let summit = null;
        let sbwt = null;
        let sbwa = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.update) {
                summit =
                    (
                        <th width="11.1%">Submit</th>
                    )
            }
            if (this.state.objs.usermatrix.approval) {
                rej =
                    (
                        <th width="11.1%">Reject</th>
                    )
            }
            if (this.state.objs.usermatrix.approval) {
                sbwt =
                    (
                        <th>Balance with Transit</th>
                    )
            }
            if (this.state.objs.usermatrix.approval) {
                sbwa =
                    (
                        <th>Actual Balance</th>
                    )
            }

        }
        return (
            <>
                <Nav type="stock_out_req_approved"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock-out Approved</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Stock-out Approved</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        {header}
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    {summit}
                                                    {rej}
                                                    <th>Name(EN)</th>
                                                    <th>Name(AM)</th>
                                                    <th>Unit</th>
                                                    <th>Department</th>
                                                    <th>QTY Requested</th>
                                                    <th>QTY Approved</th>
                                                    {sbwt}
                                                    {sbwa}
                                                    <th>Requested By</th>
                                                    <th>Approved By</th>
                                                    <th>Requested Date</th>
                                                    <th>Approved Date</th>
                                                    <th>Remark</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
                {this.modalcu2()}
            </>
        )
    }
}

export default Stock_out_req_approved;
