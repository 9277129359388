import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class CatByMenu extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',

            //modal
            cushow:false,
            iscreate:true,
            nameen:'',
            nameamh:'',
            siunit:'',
            department:'',
            type:'',
        }
    }
    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    showcu = (event,iscreate) => {
        event.preventDefault()
        this.setState({
            cushow: true,
            iscreate: iscreate,
            nameen:'',
            nameamh:'',
            siunit:'',
            department:'',
            type:'',
        })
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Category by Menu</h4></Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{width: '150px'}}>
                                        <input type="text" name="table_search"
                                               className="form-control float-right" placeholder="Search"/>
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '400px'}}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                    <tr>
                                        <th width="5.5%">#</th>
                                        <th width="11.1%">Action</th>
                                        <th>Menu Type</th>
                                        <th>Menu Name (Eng)</th>
                                        <th>Menu Name (Amh)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><a href="#" className="text-danger">Remove</a></td>
                                        <td>Food's</td>
                                        <td>Layer of Guansa</td>
                                        <td>የጓንሳ አነባበሮ</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td><a href="#" className="text-primary">Add</a></td>
                                        <td>Soft Drink's</td>
                                        <td>Guansa Dulet</td>
                                        <td>የጓንሳ አነባበ</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        this.setState({
            cushow: true,
        })
    }
    render() {
        return (
            <>
                <Nav type="catbymenu"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Category by Menu Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Cat By Menu</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Category Name</th>
                                                    <th>Total Items</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td><a onClick={this.showcu} href="#">Manage</a></td>
                                                    <td>Breakfast</td>
                                                    <td>12</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><a onClick={this.showcu} href="#">Manage</a></td>
                                                    <td>Breakfast Fasting</td>
                                                    <td>23</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><a onClick={this.showcu} href="#">Manage</a></td>
                                                    <td>Breakfast Fasting</td>
                                                    <td>14</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><a onClick={this.showcu} href="#">Manage</a></td>
                                                    <td>Lunch</td>
                                                    <td>10</td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default CatByMenu;
