import {Component} from 'react';
import axios from "axios"
import myconfig from "../myconfig";
import Swal from "sweetalert2";
import {Modal} from "react-bootstrap";

let source = axios.CancelToken.source();

class Nav extends Component {
    constructor() {
        super();

        this.state = {
            matrix: [],
            martixload: false,
            stock: 'nav-item',
            stock1: 'nav-link',
            ssiunit: 'nav-link',
            sdepartment: 'nav-link',
            semployee: 'nav-link',
            stockitem: 'nav-link',
            stockin: 'nav-link',
            stockout: 'nav-link',
            stockwastage: 'nav-link',
            rstockitem: 'nav-link',


            dashboard: 'nav-item',
            dashboard1: 'nav-link',
            d_daily_activity: 'nav-link',
            d_yesterday_activity: 'nav-link',

            purchase: 'nav-item',
            purchase1: 'nav-link',
            purchase_req: 'nav-link',
            purchase_req_approved: 'nav-link',
            purchase_req_reject: 'nav-link',
            purchase_purchased: 'nav-link',
            purchase_not_purchased: 'nav-link',
            purchase_purchased_approved: 'nav-link',
            purchase_received: 'nav-link',


            stock_out: 'nav-item',
            stock_out1: 'nav-link',
            stock_out_req: 'nav-link',
            stock_out_req_approved: 'nav-link',
            stock_out_req_reject: 'nav-link',
            stock_out_submitted: 'nav-link',
            stock_out_received: 'nav-link',

            stock_wastage: 'nav-item',
            stock_wastage1: 'nav-link',
            stock_wastage_manage: 'nav-link',
            stock_wastage_req: 'nav-link',
            stock_wastage_req_approved: 'nav-link',
            stock_wastage_req_reject: 'nav-link',


            menu: 'nav-item',
            menu1: 'nav-link',
            catagory: 'nav-link',
            menutype: 'nav-link',
            menulink: 'nav-link',
            menumatagory: 'nav-link',

            //single menus
            table: 'nav-link',
            order: 'nav-link',
            chefe: 'nav-link',
            beverage: 'nav-link',
            finance: 'nav-link',
            changepwd: 'nav-link',
            ordersforscreen: 'nav-link',


            report: 'nav-item',
            report1: 'nav-link',
            rstockin: 'nav-link',
            rstockout: 'nav-link',
            report_stock_balance: 'nav-link',
            rstockinsum: 'nav-link',
            rstockoutsum: 'nav-link',
            rstockwastage: 'nav-link',
            rorder: 'nav-link',
            rfinance: 'nav-link',
            r_daily_sales: 'nav-link',
            r_daily_sales_2: 'nav-link',
            r_order_for_waiter: 'nav-link',
            r_order_for_chafe: 'nav-link',
            r_order_for_barman: 'nav-link',
            r_order_by: 'nav-link',
            r_served_by: 'nav-link',
            r_waiting_time: 'nav-link',
            r_notcom_order: 'nav-link',
            r_removed_order: 'nav-link',

            usermgt: 'nav-item',
            usermgt1: 'nav-link',
            user: 'nav-link',
            objuser: 'nav-link',
            useraccess1: 'nav-link',
            usertable: 'nav-link',
            usertime: 'nav-link',
            auth_2: 'nav-link',

            rfollowup: 'nav-link',
            rvehiclestatus: 'nav-link',
            rordersummury: 'nav-link',
            urlorder: "",
            urlstatus: "",
            urlfollowup: "",
            urlrvehiclestatus: "",
            urlrordersummury: "",
            urldasbord1: "",

            cushow: false,
            current_password: '',
            new_password: '',
            confirm_password: '',
            current_pin: '',
        }
    }

    fetcheduseraccess = () => {
        let baseurl = myconfig.getuasession;
        let sid = window.localStorage.getItem("mycafe_sid")
        axios.post(baseurl, {
            sid: sid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(myconfig.redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data.datas)
                this.setState({
                    matrix: res.data.datas,
                    martixload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentDidMount() {
        this.fetcheduseraccess();
        if (this.props.type == "ssiunit") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    ssiunit: 'nav-link active',
                }
            );
        } else if (this.props.type == "sdepartment") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    sdepartment: 'nav-link active',
                }
            );
        } else if (this.props.type == "semployee") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    semployee: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockitem") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockitem: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockin") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockin: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockout") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockout: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockwastage") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockwastage: 'nav-link active',
                }
            );
        } else if (this.props.type == "menutype") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    menutype: 'nav-link active',
                }
            );
        } else if (this.props.type == "d_daily_activity") {
            this.setState(
                {
                    dashboard: 'nav-item menu-open active',
                    dashboard1: 'nav-link active',
                    d_daily_activity: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "d_yesterday_activity") {
            this.setState(
                {
                    dashboard: 'nav-item menu-open active',
                    dashboard1: 'nav-link active',
                    d_yesterday_activity: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "purchase_req") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_req: 'nav-link active',
                }
            );
        } else if (this.props.type == "purchase_req_approved") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_req_approved: 'nav-link active',
                }
            );
        } else if (this.props.type == "purchase_req_reject") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_req_reject: 'nav-link active',
                }
            );
        } else if (this.props.type == "purchase_purchased") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_purchased: 'nav-link active',
                }
            );
        } else if (this.props.type == "purchase_not_purchased") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_not_purchased: 'nav-link active',
                }
            );
        } else if (this.props.type == "purchase_purchased_approved") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_purchased_approved: 'nav-link active',
                }
            );
        } else if (this.props.type == "purchase_received") {
            this.setState(
                {
                    purchase: 'nav-item menu-open active',
                    purchase1: 'nav-link active',
                    purchase_received: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_req") {
            this.setState(
                {
                    stock_out: 'nav-item menu-open active',
                    stock_out1: 'nav-link active',
                    stock_out_req: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_req_approved") {
            this.setState(
                {
                    stock_out: 'nav-item menu-open active',
                    stock_out1: 'nav-link active',
                    stock_out_req_approved: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_req_reject") {
            this.setState(
                {
                    stock_out: 'nav-item menu-open active',
                    stock_out1: 'nav-link active',
                    stock_out_req_reject: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_submitted") {
            this.setState(
                {
                    stock_out: 'nav-item menu-open active',
                    stock_out1: 'nav-link active',
                    stock_out_submitted: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_received") {
            this.setState(
                {
                    stock_out: 'nav-item menu-open active',
                    stock_out1: 'nav-link active',
                    stock_out_received: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_wastage_manage") {
            this.setState(
                {
                    stock_wastage: 'nav-item menu-open active',
                    stock_wastage1: 'nav-link active',
                    stock_wastage_manage: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_wastage_req") {
            this.setState(
                {
                    stock_wastage: 'nav-item menu-open active',
                    stock_wastage1: 'nav-link active',
                    stock_wastage_req: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_wastage_req_approved") {
            this.setState(
                {
                    stock_wastage: 'nav-item menu-open active',
                    stock_wastage1: 'nav-link active',
                    stock_wastage_req_approved: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_wastage_req_reject") {
            this.setState(
                {
                    stock_wastage: 'nav-item menu-open active',
                    stock_wastage1: 'nav-link active',
                    stock_wastage_req_reject: 'nav-link active',
                }
            );
        } else if (this.props.type == "catagory") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    catagory: 'nav-link active',
                }
            );
        } else if (this.props.type == "menulink") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    menulink: 'nav-link active',
                }
            );
        } else if (this.props.type == "menumatagory") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    menumatagory: 'nav-link active',
                }
            );
        } else if (this.props.type == "catbymenu") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    catbymenu: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockin") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockin: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockout") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockout: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "report_stock_balance") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    report_stock_balance: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "rstockinsum") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockinsum: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "rstockoutsum") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockoutsum: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockwastage") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockwastage: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockitem") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockitem: 'nav-link active',
                }
            );
        } else if (this.props.type == "rorder") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rorder: 'nav-link active',
                }
            );
        } else if (this.props.type == "rfinance") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rfinance: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_daily_sales") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_daily_sales: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_daily_sales_2") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_daily_sales_2: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_order_for_waiter") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_order_for_waiter: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_order_for_chafe") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_order_for_chafe: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_order_for_barman") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_order_for_barman: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_order_by") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_order_by: 'nav-link active',
                }
            );
        } else if (this.props.type == "r_served_by") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_served_by: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_waiting_time") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_waiting_time: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_notcom_order") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_notcom_order: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_removed_order") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    r_removed_order: 'nav-link active',
                }
            );
        } else if (this.props.type == "user") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    user: 'nav-link active',
                }
            );
        } else if (this.props.type == "objuser") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    objuser: 'nav-link active',
                }
            );
        } else if (this.props.type == "useraccess1") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    useraccess1: 'nav-link active',
                }
            );
        } else if (this.props.type == "usertable") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    usertable: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "usertime") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    usertime: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "auth_2") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    auth_2: 'nav-link active',
                }
            );
        } else if (this.props.type == "table") {
            this.setState(
                {
                    table: 'nav-link active',
                }
            );
        } else if (this.props.type == "order") {
            this.setState(
                {
                    order: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "ordersforscreen") {
            this.setState(
                {
                    ordersforscreen: 'nav-link active',
                }
            );
        } else if (this.props.type == "chefe") {
            this.setState(
                {
                    chefe: 'nav-link active',
                }
            );
        } else if (this.props.type == "beverage") {
            this.setState(
                {
                    beverage: 'nav-link active',
                }
            );
        } else if (this.props.type == "finance") {
            this.setState(
                {
                    finance: 'nav-link active',
                }
            );
        }
    }

    logout = (event) => {
        event.preventDefault();
        let baseurl = myconfig.logout
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
        }).catch((e) => {
            console.log(e.message);
        });
        window.localStorage.setItem("mycafe_sid", null);
        window.localStorage.setItem("mycafe_username", null);
        window.localStorage.setItem("mycafe_name", null);
        if (window.localStorage.getItem("mycafe_sid_pin") == "true")
            var win = window.open(myconfig.redirecttoauth_2, "_self");
        else
            var win = window.open(myconfig.redirecttologin, "_self");
    }

    displaymenus = () => {
        if (this.state.martixload) {
            let siunit = "";
            let department = "";
            let employee = "";
            let stockitem = "";
            let stockin = "";
            let stockout = "";

            let user = "";
            let objuser = "";
            let useraccess1 = "";
            let usertable = "";
            let usertime = "";
            let auth_2 = "";


            let dashboardmenu = "";
            let stockmenu = "";
            let purchasemenu = "";
            let stockoutmenu = "";
            let stockwastagemenu = "";
            let usermenu = "";
            let reportmenu = "";
            let menumenu = "";


            let menutype = "";
            let menucat = "";
            let menus = "";
            let menu_menu_cat = "";

            let purchase_req = "";
            let purchase_req_approved = "";
            let purchase_req_reject = "";
            let purchase_purchased = "";
            let purchase_not_purchased = "";
            let purchase_purchased_approved = "";
            let purchase_received = "";

            let stock_out_req = "";
            let stock_out_req_approved = "";
            let stock_out_req_reject = "";
            let stock_out_submitted = "";
            let stock_out_received = "";


            let d_daily_activity = "";
            let d_yesterday_activity = "";

            let dashboardmgt = this.state.matrix.filter(value => {
                if (value.object_name == "d_daily_activity" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    d_daily_activity =
                        <a href={myconfig.urld_daily_activity} className={this.state.d_daily_activity}><i
                            className="far fa-circle nav-icon"/>
                            <p>Daily Activity</p></a>
                    return value;
                }
                if (value.object_name == "d_yesterday_activity" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    d_yesterday_activity =
                        <a href={myconfig.urld_yesterday_activity} className={this.state.d_yesterday_activity}><i
                            className="far fa-circle nav-icon"/>
                            <p>Yesterday Activity</p></a>
                    return value;
                }
            })


            let stock_wastage_manage = "";
            let stock_wastage_req = "";
            let stock_wastage_req_approved = "";
            let stock_wastage_req_reject = "";

            let stockmgt = this.state.matrix.filter(value => {
                if (value.object_name == "siunit" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    siunit =
                        <a href={myconfig.urlsiunit} className={this.state.ssiunit}><i
                            className="far fa-circle nav-icon"/>
                            <p>Unit of Measurement</p></a>
                    return value;
                }
                if (value.object_name == "department" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    department = <a href={myconfig.urldepartment} className={this.state.sdepartment}><i
                        className="far fa-circle nav-icon"/><p>Department</p></a>
                    return value;
                }
              /*  if (value.object_name == "employee" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    employee = <a href={myconfig.urlemployee} className={this.state.semployee}><i
                        className="far fa-circle nav-icon"/><p>Employee</p></a>
                    return value;
                }*/
                if (value.object_name == "stockitem" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stockitem = <a href={myconfig.urlstockitem} className={this.state.stockitem}><i
                        className="far fa-circle nav-icon"/><p>Stock Item</p></a>
                    return value;
                }
               /* if (value.object_name == "stockin" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stockin = <a href={myconfig.urlstockin} className={this.state.stockin}><i
                        className="far fa-circle nav-icon"/><p>Stock IN</p></a>
                    return value;
                }
                if (value.object_name == "stockout" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stockout = <a href={myconfig.urlstockout} className={this.state.stockout}><i
                        className="far fa-circle nav-icon"/><p>Stock Out</p></a>
                    return value;
                }*/
            })
            let purchasemgt = this.state.matrix.filter(value => {
                if (value.object_name == "purchase_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_req =
                        <a href={myconfig.urlpurchase_req} className={this.state.purchase_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Order</p></a>
                    return value;
                }
                if (value.object_name == "purchase_req_approved" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_req_approved =
                        <a href={myconfig.urlpurchase_req_approved} className={this.state.purchase_req_approved}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Order Approved</p></a>
                    return value;
                }
                if (value.object_name == "purchase_req_reject" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_req_reject =
                        <a href={myconfig.urlpurchase_req_reject} className={this.state.purchase_req_reject}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Order Reject</p></a>
                    return value;
                }
                if (value.object_name == "purchase_purchased" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_purchased =
                        <a href={myconfig.urlpurchase_purchased} className={this.state.purchase_purchased}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Purchased</p></a>
                    return value;
                }
                if (value.object_name == "purchase_not_purchased" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_not_purchased =
                        <a href={myconfig.urlpurchase_not_purchased} className={this.state.purchase_not_purchased}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Not-purchased</p></a>
                    return value;
                }
                if (value.object_name == "purchase_purchased_approved" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_purchased_approved =
                        <a href={myconfig.urlpurchase_purchased_approved}
                           className={this.state.purchase_purchased_approved}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Purchased Approved</p></a>
                    return value;
                }
                if (value.object_name == "purchase_received" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_received =
                        <a href={myconfig.urlpurchase_received} className={this.state.purchase_received}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Received</p></a>
                    return value;
                }
            })
            let stockoutmgt = this.state.matrix.filter(value => {
                if (value.object_name == "stock_out_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_req =
                        <a href={myconfig.urlstock_out_req} className={this.state.stock_out_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock-out Request</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_req_approved" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_req_approved =
                        <a href={myconfig.urlstock_out_req_approved} className={this.state.stock_out_req_approved}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock-out Approved</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_req_reject" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_req_reject =
                        <a href={myconfig.urlstock_out_req_reject} className={this.state.stock_out_req_reject}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock-out Rejected</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_submitted" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_submitted =
                        <a href={myconfig.urlstock_out_submitted} className={this.state.stock_out_submitted}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock-out Submitted</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_received" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_received =
                        <a href={myconfig.urlstock_out_received} className={this.state.stock_out_received}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock-out Received</p></a>
                    return value;
                }

            })
            let stockwastagemgt = this.state.matrix.filter(value => {
                if (value.object_name == "stock_wastage_manage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_wastage_manage =
                        <a href={myconfig.urlstock_wastage_manage} className={this.state.stock_wastage_manage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Wastage Manage</p></a>
                    return value;
                }
                if (value.object_name == "stock_wastage_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_wastage_req =
                        <a href={myconfig.urlstock_wastage_req} className={this.state.stock_wastage_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Requested Wastage</p></a>
                    return value;
                }
                if (value.object_name == "stock_wastage_req_approved" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_wastage_req_approved =
                        <a href={myconfig.urlstock_wastage_req_approved}
                           className={this.state.stock_wastage_req_approved}><i
                            className="far fa-circle nav-icon"/>
                            <p>Approved Wastage</p></a>
                    return value;
                }
                if (value.object_name == "stock_wastage_req_reject" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_wastage_req_reject =
                        <a href={myconfig.urlstock_wastage_req_reject}
                           className={this.state.stock_wastage_req_reject}><i
                            className="far fa-circle nav-icon"/>
                            <p>Rejected Wastage</p></a>
                    return value;
                }
            })
            let usermgt = this.state.matrix.filter(value => {
                if (value.object_name == "user" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    user =
                        <a href={myconfig.urluser} className={this.state.user}><i className="far fa-circle nav-icon"/>
                            <p>User</p></a>
                    return value;
                }
                if (value.object_name == "objuser" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    objuser =
                        <a href={myconfig.urlobjuser} className={this.state.objuser}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Object</p></a>
                    return value;
                }
                if (value.object_name == "useraccess1" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    useraccess1 =
                        <a href={myconfig.useraccess1} className={this.state.useraccess1}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Access</p></a>
                    return value;
                }
                if (value.object_name == "usertable" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    usertable =
                        <a href={myconfig.urlusertable} className={this.state.usertable}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Table</p></a>
                    return value;
                }
                if (value.object_name == "usertime" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    usertime =
                        <a href={myconfig.urlusertime} className={this.state.usertime}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Time</p></a>
                    return value;
                }
                if (value.object_name == "auth_2" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    auth_2 =
                        <a href={myconfig.urlauth_2} target="_blank" className={this.state.auth_2}><i
                            className="far fa-circle nav-icon"/>
                            <p>Auth-2</p></a>
                    return value;
                }
            })

            let rstockin = "";
            let rstockout = "";
            let report_stock_balance = "";
            let rstockinsum = "";
            let rstockoutsum = "";
            let rstockwastage = "";
            let rstockitem = "";
            let rorderitem = "";
            let rfinanceitem = "";
            let r_daily_sales = "";
            let r_daily_sales_2 = "";
            let r_order_for_waiter = "";
            let r_order_for_chafe = "";
            let r_order_for_barman = "";
            let r_order_by = "";
            let r_served_by = "";
            let r_waiting_time = "";
            let r_notcom_order = "";
            let r_removed_order = "";
            let reportmgt = this.state.matrix.filter(value => {
                if (value.object_name == "rstockin" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockin =
                        <a href={myconfig.urlrstockin} className={this.state.rstockin}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock In</p></a>
                    return value;
                }
                if (value.object_name == "rstockout" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockout =
                        <a href={myconfig.urlrstockout} className={this.state.rstockout}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Out</p></a>
                    return value;
                }
                if (value.object_name == "report_stock_balance" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    report_stock_balance =
                        <a href={myconfig.urlreport_stock_balance} className={this.state.report_stock_balance}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Balance</p></a>
                    return value;
                }
                if (value.object_name == "rstockinsum" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockinsum =
                        <a href={myconfig.urlrstockinsum} className={this.state.rstockinsum}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock In Summary</p></a>
                    return value;
                }
                if (value.object_name == "rstockoutsum" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockoutsum =
                        <a href={myconfig.urlrstockoutsum} className={this.state.rstockoutsum}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Out Summary</p></a>
                    return value;
                }
                if (value.object_name == "rstockwastage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockwastage =
                        <a href={myconfig.urlrstockwastage} className={this.state.rstockwastage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Wastage Report</p></a>
                    return value;
                }
                if (value.object_name == "rstockitem" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockitem =
                        <a href={myconfig.urlrstockitem} className={this.state.rstockitem}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Item Report</p></a>
                    return value;
                }
                if (value.object_name == "rorder" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rorderitem =
                        <a href={myconfig.urlrorder} className={this.state.rorder}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order Report</p></a>
                    return value;
                }
                if (value.object_name == "rfinance" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rfinanceitem =
                        <a href={myconfig.urlrfinance} className={this.state.rfinance}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order Finance</p></a>
                    return value;
                }
                if (value.object_name == "r_daily_sales" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_daily_sales =
                        <a href={myconfig.urlr_daily_sales} className={this.state.r_daily_sales}><i
                            className="far fa-circle nav-icon"/>
                            <p>Sales by Category</p></a>
                    return value;
                }
                if (value.object_name == "r_daily_sales_2" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_daily_sales_2 =
                        <a href={myconfig.urlr_daily_sales_2} className={this.state.r_daily_sales_2}><i
                            className="far fa-circle nav-icon"/>
                            <p>Sales by Menu</p></a>
                    return value;
                }
                if (value.object_name == "r_order_for_waiter" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_order_for_waiter =
                        <a href={myconfig.urlr_order_for_waiter} className={this.state.r_order_for_waiter}><i
                            className="far fa-circle nav-icon"/>
                            <p>Waiter Report</p></a>
                    return value;
                }
                if (value.object_name == "r_order_for_chafe" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_order_for_chafe =
                        <a href={myconfig.urlr_order_for_chafe} className={this.state.r_order_for_chafe}><i
                            className="far fa-circle nav-icon"/>
                            <p>Chafe Report</p></a>
                    return value;
                }
                if (value.object_name == "r_order_for_barman" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_order_for_barman =
                        <a href={myconfig.urlr_order_for_barman} className={this.state.r_order_for_barman}><i
                            className="far fa-circle nav-icon"/>
                            <p>Barman Report</p></a>
                    return value;
                }
                if (value.object_name == "r_order_by" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_order_by =
                        <a href={myconfig.urlr_order_by} className={this.state.r_order_by}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order By</p></a>
                    return value;
                }
                if (value.object_name == "r_served_by" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_served_by =
                        <a href={myconfig.urlr_served_by} className={this.state.r_served_by}><i
                            className="far fa-circle nav-icon"/>
                            <p>Served By</p></a>
                    return value;
                }
                if (value.object_name == "r_waiting_time" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_waiting_time =
                        <a href={myconfig.urlr_waiting_time} className={this.state.r_waiting_time}><i
                            className="far fa-circle nav-icon"/>
                            <p>Waiting Time</p></a>
                    return value;
                }
                if (value.object_name == "r_notcom_order" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_notcom_order =
                        <a href={myconfig.urlr_notcom_order} className={this.state.r_notcom_order}><i
                            className="far fa-circle nav-icon"/>
                            <p>Incomplete Order</p></a>
                    return value;
                }
                if (value.object_name == "r_removed_order" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_removed_order =
                        <a href={myconfig.urlr_removed_order} className={this.state.r_removed_order}><i
                            className="far fa-circle nav-icon"/>
                            <p>Canceled Order</p></a>
                    return value;
                }
            })
            let menumgt = this.state.matrix.filter(value => {
                if (value.object_name == "menutype" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menutype =
                        <a href={myconfig.urlmenutype} className={this.state.menutype}><i
                            className="far fa-circle nav-icon"/>
                            <p>Menu Type</p></a>
                    return value;
                }
                if (value.object_name == "catagory" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menucat =
                        <a href={myconfig.urlcatagory} className={this.state.catagory}><i
                            className="far fa-circle nav-icon"/>
                            <p>Category</p></a>
                    return value;
                }
                if (value.object_name == "menu" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menus =
                        <a href={myconfig.urlmenulink} className={this.state.menulink}><i
                            className="far fa-circle nav-icon"/>
                            <p>Menus</p></a>
                    return value;
                }
                if (value.object_name == "menumatagory" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menu_menu_cat =
                        <a href={myconfig.urlmenumatagory} className={this.state.menumatagory}><i
                            className="far fa-circle nav-icon"/>
                            <p>Menu & Category</p></a>
                    return value;
                }
            })

            let singlemenu_table = this.state.matrix.filter(value => {
                if (value.object_name == "table" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_table.length > 0)
                singlemenu_table = <li className="nav-item">
                    <a href={myconfig.urltable} className={this.state.table}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Table</p>
                    </a>
                </li>
            else
                singlemenu_table = null;

            let singlemenu_order = this.state.matrix.filter(value => {
                if (value.object_name == "order" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_order.length > 0)
                singlemenu_order = <li className="nav-item">
                    <a href={myconfig.urlorder} className={this.state.order}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Order Management</p>
                    </a>
                </li>
            else
                singlemenu_order = null;

            let singlemenu_chefe = this.state.matrix.filter(value => {
                if (value.object_name == "chefe" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_chefe.length > 0)
                singlemenu_chefe = <li className="nav-item">
                    <a href={myconfig.urlchefe} className={this.state.chefe}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Chefe</p>
                    </a>
                </li>
            else
                singlemenu_chefe = null;

            let singlemenu_beverage = this.state.matrix.filter(value => {
                if (value.object_name == "beverage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_beverage.length > 0)
                singlemenu_beverage = <li className="nav-item">
                    <a href={myconfig.urlbeverage} className={this.state.beverage}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Barman</p>
                    </a>
                </li>
            else
                singlemenu_beverage = null;
            let singlemenu_finance = this.state.matrix.filter(value => {
                if (value.object_name == "finance" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_finance.length > 0)
                singlemenu_finance = <li className="nav-item">
                    <a href={myconfig.urlfinance} className={this.state.finance}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Finance</p>
                    </a>
                </li>
            else
                singlemenu_finance = null;

            let singlemenu_changepwd = this.state.matrix.filter(value => {
                if (value.object_name == "changepwd" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            let singlemenu_ordersforscreen = this.state.matrix.filter(value => {
                if (value.object_name == "ordersforscreen" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_changepwd.length > 0)
                singlemenu_changepwd = <li className="nav-item">
                    <a href="" onClick={this.showcu} className={this.state.changepwd}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Change Password</p>
                    </a>
                </li>
            else
                singlemenu_changepwd = null;
            if (singlemenu_ordersforscreen.length > 0)
                singlemenu_ordersforscreen = <li className="nav-item">
                    <a href={myconfig.urlordersforscreen} className={this.state.ordersforscreen}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Display Order</p>
                    </a>
                </li>
            else
                singlemenu_ordersforscreen = null;


            if (dashboardmgt.length > 0) {
                dashboardmenu =
                    <li name="dashboard" onClick={(event => {
                        this.menuclick(event, this.state.dashboard)
                    })} className={this.state.dashboard}>
                        <a href="#" name="dashboard" onClick={(event => {
                            this.menuclick(event, this.state.dashboard)
                        })} className={this.state.dashboard1}>
                            <i className="nav-icon fas fa-tachometer-alt"/>
                            <p>
                                Dashboard
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                {d_daily_activity}
                                {d_yesterday_activity}
                            </li>
                        </ul>
                    </li>
            }
            if (stockmgt.length > 0) {
                stockmenu =
                    <li name="stock" onClick={(event => {
                        this.menuclick(event, this.state.stock)
                    })} className={this.state.stock}>
                        <a href="#" name="stock" onClick={(event => {
                            this.menuclick(event, this.state.stock)
                        })} className={this.state.stock1}>
                            <i className="nav-icon fas fa-box-open"/>
                            <p>
                                Stock Management
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                {siunit}
                                {department}
                                {employee}
                                {stockitem}
                                {stockin}
                                {stockout}
                            </li>
                        </ul>
                    </li>
            }
            if (purchasemgt.length > 0) {
                purchasemenu =
                    <li name="purchase" onClick={(event => {
                        this.menuclick(event, this.state.stock)
                    })} className={this.state.purchase}>
                        <a href="#" name="purchase" onClick={(event => {
                            this.menuclick(event, this.state.purchase)
                        })} className={this.state.purchase1}>
                            <i className="nav-icon fas fa-cart-arrow-down"/>
                            <p>
                                Purchase Management
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                {purchase_req}
                                {purchase_req_approved}
                                {purchase_req_reject}
                                {purchase_purchased}
                                {purchase_not_purchased}
                                {purchase_purchased_approved}
                                {purchase_received}
                            </li>
                        </ul>
                    </li>
            }
            if (stockoutmgt.length > 0) {
                stockoutmenu =
                    <li name="stock_out" onClick={(event => {
                        this.menuclick(event, this.state.stock_out)
                    })} className={this.state.stock_out}>
                        <a href="#" name="stock_out" onClick={(event => {
                            this.menuclick(event, this.state.stock_out)
                        })} className={this.state.stock_out1}>
                            <i className="nav-icon fas fa-box-tissue"/>
                            <p>
                                Stock_out Management
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                {stock_out_req}
                                {stock_out_req_approved}
                                {stock_out_req_reject}
                                {stock_out_submitted}
                                {stock_out_received}
                            </li>
                        </ul>
                    </li>
            }
            if (stockwastagemgt.length > 0) {
                stockwastagemenu =
                    <li name="stock_wastage" onClick={(event => {
                        this.menuclick(event, this.state.stock_wastage)
                    })} className={this.state.stock_wastage}>
                        <a href="#" name="stock_wastage" onClick={(event => {
                            this.menuclick(event, this.state.stock_wastage)
                        })} className={this.state.stock_wastage1}>
                            <i className="nav-icon fas fa-trash"/>
                            <p>
                                Wastage Management
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                {stock_wastage_manage}
                                {stock_wastage_req}
                                {stock_wastage_req_approved}
                                {stock_wastage_req_reject}
                            </li>
                        </ul>
                    </li>
            }
            if (usermgt.length > 0) {
                usermenu = <li name="usermgt" onClick={(event => {
                    this.menuclick(event, this.state.usermgt)
                })} className={this.state.usermgt}>
                    <a name="usermgt" onClick={(event => {
                        this.menuclick(event, this.state.usermgt)
                    })} href="#" className={this.state.usermgt1}>
                        <i className="nav-icon fas fa-user"/>
                        <p>
                            User Managment
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {user}
                            {objuser}
                            {useraccess1}
                            {usertable}
                            {usertime}
                            {auth_2}
                        </li>
                    </ul>
                </li>
            }
            if (reportmgt.length > 0) {
                reportmenu = <li name="report" onClick={(event => {
                    this.menuclick(event, this.state.report)
                })} className={this.state.report}>
                    <a name="report" onClick={(event => {
                        this.menuclick(event, this.state.report)
                    })} href="#" className={this.state.report1}>
                        <i className="nav-icon fas fa-bookmark"/>
                        <p>
                            Report
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {rstockin}
                            {rstockout}
                            {report_stock_balance}
                            {rstockinsum}
                            {rstockoutsum}
                            {rstockitem}
                            {rorderitem}
                            {rfinanceitem}
                            {r_daily_sales}
                            {r_daily_sales_2}
                            {r_order_for_waiter}
                            {r_order_for_chafe}
                            {r_order_for_barman}
                            {r_order_by}
                            {r_served_by}
                            {r_waiting_time}
                            {r_notcom_order}
                            {r_removed_order}
                        </li>
                    </ul>
                </li>
            }
            if (menumgt.length > 0) {
                menumenu = <li name="menu" onClick={(event => {
                    this.menuclick(event, this.state.menu)
                })} className={this.state.menu}>
                    <a name="menu" onClick={(event => {
                        this.menuclick(event, this.state.menu)
                    })} href="#" className={this.state.menu1}>
                        <i className="nav-icon fas fa-book-dead"/>
                        <p>
                            Menu
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {menutype}
                            {menucat}
                            {menus}
                            {menu_menu_cat}
                        </li>
                    </ul>
                </li>
            }
            return <>
                {dashboardmenu}
                {stockmenu}
                {purchasemenu}
                {stockoutmenu}
                {stockwastagemenu}
                {usermenu}
                {reportmenu}
                {menumenu}
                {singlemenu_table}
                {singlemenu_order}
                {singlemenu_chefe}
                {singlemenu_beverage}
                {singlemenu_finance}
                {singlemenu_changepwd}
                {singlemenu_ordersforscreen}

            </>
        } else {
            return (<></>)
        }
    }

    menuclick = (event, value) => {
        var name = event.target.name;
        if (value == 'nav-item')
            value = 'nav-item menu-open active';
        else
            value = 'nav-item'
        this.setState({
            [name]: value,
        })
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    modalcu=()=> {
        let displayed= <div className="row">
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>Current Password</label>
                    <input className="form-control" type="password" name="current_password"
                           value={this.state.current_password}  onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>New Password</label>
                    <input className="form-control" type="password" name="new_password"
                           value={this.state.new_password}  onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input className="form-control" type="password"
                           name="confirm_password" value={this.state.confirm_password}  onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-md-12 px-3">
                <div className="form-group d-flex justify-content-around">
                    <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                    <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                </div>
            </div>
        </div>;

        console.log((window.localStorage.getItem("mycafe_sid_pin")));
        if(window.localStorage.getItem("mycafe_sid_pin")=="true"){

            console.log("I am here");
            displayed= <div className="row">
                <div className="col-12 px-3">
                    <div className="form-group">
                        <label>Current PIN</label>
                        <input className="form-control" type="password" name="current_pin"
                               value={this.state.current_pin} onChange={this.inputmgt}/>
                    </div>
                </div>
                <div className="col-md-12 px-3">
                    <div className="form-group d-flex justify-content-around">
                        <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                        <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                    </div>
                </div>
            </div>;
        }

        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Change Password</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {displayed}
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    showcu = (event) => {
        event.preventDefault()
        this.setState({
            cushow:true,
        })
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.changepwd_by_user;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            current_password: this.state.current_password,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password,
            current_pin: this.state.current_pin,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    cushow:false,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }


    render() {
        return (
            <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i
                                className="fas fa-bars"/></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link">Contact</a>
                        </li>
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="" onClick={this.logout}
                               role="button">
                                <i className="fas fa-sign-out-alt"/>
                            </a>
                        </li>
                    </ul>
                </nav>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a href="#" className="brand-link text-sm">
                        <img src="dist/img/logo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-5"
                             style={{opacity: '.8'}}/>
                        <span className="brand-text font-weight-light">TM</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                            </div>
                            <div className="info">
                                <a href="#" className="d-block">TECHNO MA'ED</a>
                            </div>
                        </div>
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column text-sm" data-widget="treeview"
                                role="menu" data-accordion="false">
                                {this.displaymenus()}
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
                {this.modalcu()}
            </>
        );
    }
}
export default Nav;
