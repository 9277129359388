import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
let source = axios.CancelToken.source();

class R_daily_sales extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            id: -1,
            st: '',
            ed: '',
            report_type: 'all',
        }
    }
    componentDidMount() {
    }
    fetcheddata = () => {
        let baseurl = myconfig.report_daily_sale;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            st:this.state.st,
            ed:this.state.ed,
            report_type:this.state.report_type,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    tabledata=()=> {
        let retdata = null;
        if (this.state.objload) {
            let count = 0;
            retdata = this.state.obj.menu_types.map(value => {
                let menu_types=this.state.obj.menu_return.filter(v=>{
                    if(value.id==v.type_id)
                        return v;
                })
                let summury=this.state.obj.summury.filter(v=>{
                    if(value.id==v.type_id)
                        return v;
                })
                let menu_data=menu_types.map(v2 => {
                    return(
                        <tr>
                            <td>{v2.name_en}</td>
                            <td>{v2.name_am}</td>
                            <td>{v2.count}</td>
                            <td>{v2.price}</td>
                            <td>{v2.total}</td>
                        </tr>
                    )
                })
                let sum_data=null;
                if(summury.length>0) {
                    sum_data = <>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>Total</th>
                            <th>{summury[0].total}</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>15 % tot</th>
                            <th>{summury[0].tot}</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>Income</th>
                            <th>{summury[0].income}</th>
                        </tr>
                    </>
                }
                return (
                    <>
                        <tr>
                            <th colSpan={5}>{value.type}</th>
                        </tr>
                        <tr>
                            <th>Name(EN)</th>
                            <th>Name(AM)</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                        {menu_data}
                        {sum_data}
                    </>
                )
            })
            return (<div className="card-body table-responsive p-0">
                <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                        <th colSpan={5}><strong>Date:</strong> {this.state.obj.dt}</th>
                    </tr>
                    {retdata}
                    </thead>
                </table>
            </div>)
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btngetreport = (event) => {
        event.preventDefault();
        this.fetcheddata()
    }

    render() {
        return (
            <>
                <Nav type="r_daily_sales"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Daily Sales Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Sale Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Report Criteria</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <input  className="form-control" type="date" name="st" onChange={this.inputmgt} value={this.state.st}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <input  className="form-control" type="date" name="ed" onChange={this.inputmgt} value={this.state.ed}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>Report Type</label>
                                                        <select  className="form-control" name="report_type" onChange={this.inputmgt} value={this.state.report_type}>
                                                            <option value="all">All</option>
                                                            <option value="sale">Sold</option>
                                                            <option value="not_sale">Not Sold</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <button  className="btn btn-outline-primary form-control" onClick={this.btngetreport}> Get Report</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daily Sales by Category</h3>
                                        </div>
                                        {this.tabledata()}
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default R_daily_sales;
