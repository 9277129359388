import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class Usertime extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            obj: [],
            objload: false,

            //modal
            cushow:false,
            sttime:'',
            edtime:'',
            name:'',
            username:'',


            iscreate:true,
            id: '-1',
            password:'',

            email:'',
            isadmin:false,
            isactive:true,

            //modal 2
            pwdshow: false,
            newpwd: '',
            pwdid: '-1',

            isexpirable:false,
            ispin:false,
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let baseurl = myconfig.get_usertimes;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            obj: [],
            cushow:false,
            objload: false,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    modalchangepwd=()=> {
        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.pwdshow}
                onHide={this.hidechangepwd}>
                <Modal.Header><h4>User Password Change</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>New password</label>
                                                <input  className="form-control" type="password" name="newpwd" onChange={this.inputmgt} value={this.state.newpwd}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitchangepwd}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidechangepwd}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitchangepwd = (event) => {
        event.preventDefault()
        let baseurl = myconfig.changepwduser;
        axios.post(baseurl, {
            id: this.state.pwdid,
            newpwd: this.state.newpwd,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.successumit()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    showchangepwd = (event,id=-1) => {
        event.preventDefault()
        this.setState({
            pwdshow: true,
            newpwd: '',
            pwdid: id,
        })
    }
    hidechangepwd = () => {
        this.setState({
            pwdshow:false,
        })
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgtchk = (event) => {
        var name = event.target.name;
        var value = event.target.checked;
        this.setState({
            [name]: value,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>User-Time Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input disabled={true}  className="form-control" type="text" name="name" value={this.state.name}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Username</label>
                                                <input disabled={true}  className="form-control" type="text" name="username" value={this.state.username}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <div className="form-group">
                                                <label>Start Time</label>
                                                <input  className="form-control" type="text" name="sttime" onChange={this.inputmgt} placeholder="HH:MM" value={this.state.sttime}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <div className="form-group">
                                                <label>End Time</label>
                                                <input  className="form-control" type="text" name="edtime" onChange={this.inputmgt} placeholder="HH:MM" value={this.state.edtime}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    showcu = (event,id=-1) => {
        event.preventDefault()
        if(this.state.objload){
            let data=this.state.obj.datas.filter(v=>{
               if(v.id==id)
                   return v;
            });
            if(data.length==0){
                Swal.fire({
                    title: 'Error!',
                    text: "No data is found",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else
            {
                this.setState({
                    cushow: true,
                    id: data[0].id,
                    name: data[0].name,
                    username: data[0].username,
                    sttime: data[0].sttime,
                    edtime: data[0].edtime,
                })
            }
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.update_usertime;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            st: this.state.sttime,
            ed: this.state.edtime,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count=count+1
                let change_time=null;
                if (this.state.obj.usermatrix.update)
                    change_time = <td>
                        <a href="" onClick={(event => {
                            this.showcu(event,  value.id)
                        })}>Manage</a>
                    </td>
                return (<tr>
                    <td>{count}</td>
                    {change_time}
                    <td>{value.username}</td>
                    <td>{value.name}</td>
                    <td>{value.stdate}</td>
                    <td>{value.eddate}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    expire_st_ed=()=> {
        let display = null;
        if (this.state.isexpirable)
            display = <>
                <div className="col-md-6 px-3 pb-3">
                    <div className="form-group">
                        <label>Start Time</label>
                        <input className="form-control" type="text" name="st" onChange={this.inputmgt}
                               value={this.state.st} placeholder="HH:MM"/>
                    </div>
                </div>
                <div className="col-md-6 px-3 pb-3">
                    <div className="form-group">
                        <label>End Time</label>
                        <input className="form-control" type="text" name="ed" onChange={this.inputmgt}
                               value={this.state.ed} placeholder="HH:MM"/>
                    </div>
                </div>
            </>
        return (<>{display}</>)
    }


    successumit=()=> {
        this.setState({
            siunit: '',
            cushow: false,
            pwdshow: false,
        }, () => this.fetcheddata())
    }
    render() {
        let change_time=null;
        if(this.state.objload) {
            if (this.state.obj.usermatrix.update)
                change_time = <th width="11.1%">Action</th>
        }
        return (
            <>
                <Nav type="usertime"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">User Time Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">User-time</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    {change_time}
                                                    <th>Username</th>
                                                    <th>Full Name</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
                {this.modalchangepwd()}
            </>
        )
    }
}

export default Usertime;
