import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import Select from 'react-select'
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class MenuCatagory extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            filtered:[],
            menus:[],
            typedata:[],
            filteredMenus:[],
            menu_catagory:'',
            objsload: false,
            id:-1,
            cushow:false,
            iscreate:true,
            txtsearch:'',

            status:'',
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        this.setState({
            objs: [],
            menus:[],
            typedata:[],
            filteredMenus:[],
            menu_catagory:'',
            objsload: false,
            //modal
            cushow:false,
            iscreate:true,
            status:'',
            txtsearch:'',
            selectedindex:-1,
        })
        let baseurl = myconfig.getcatgorys;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).
        then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchtype = () =>{
        let baseurl = myconfig.getmenutypes;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {

            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                var result=res.data.datas
                const typedata=result.map((index,response) => ({
                    "value" : index.type,
                    "label" : index.type
                }));
                this.setState({
                    typedata
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    showcu = (event,menuCatagory, id=-1) => {
        event.preventDefault()
        this.fetchtype()
        this.setState({
            cushow: true,
            id: id,
            menu_catagory: menuCatagory,
        }, () => this.fetchtoogledata())
    }
    fetchtoogledata=()=>{
        let baseurl = myconfig.getmenucat;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            catagories_id: this.state.id,
        }, {cancelToken: source.token}).then(res => {
            // console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.datas == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        menus:res.data.datas,
                        filteredMenus:res.data.datas
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    togglemenu = (event,menu_id=-1,st) => {
        event.preventDefault()
        let isinculde = 0;
        if (st == "Include")
            isinculde = 1;
        let baseurl = myconfig.togglemenucat;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            menu_id: menu_id,
            catagories_id: this.state.id,
            isinculde: isinculde,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(() => {
                    this.fetchtoogledata()
                })
            }
            //
            //}
            //}
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    menudata=()=>{
        let retdata=null;
        let count = 0;
        //retdata = this.state.objs.datas.map
        //this.showcu(event,false,value.id)---for edit
        retdata = this.state.filteredMenus.map(value => {
            count=count+1
            let st=value.status=="Not-Include"?"Include":"Not-Include";
            return (<tr>
                <td><a href="" onClick={(event => {this.togglemenu(event,value.id,st)})} >{st}</a></td>
                <td>{value.name_am}</td>
                <td>{value.name_en}</td>
                <td>{value.price}</td>
                <td>{value.menu_types}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>{retdata}</>)
    }
    modalcu=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Menu Catagory({this.state.menu_catagory}) Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-tools">
                                        <div className="input-group input-group-sm" style={{width: '150px'}}>
                                            <input type="text" name="txtsearch"
                                                   className="form-control float-right" value={this.state.txtsearch} onChange={(event => {this.inputsearch(event,false)})} placeholder="Search"/>
                                            <div className="input-group-append">
                                                <button type="submit" className="btn btn-default">
                                                    <i className="fas fa-search"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <div className="card-tools" style={{width: '150px'}}>
                                        <Select name="type" onChange={(event => {this.inputsearch(event,true)})} options={this.state.typedata} />

                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                            <table className="table table-head text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Menu Amharic</th>
                                                    <th>Menu English</th>
                                                    <th>Price</th>
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.menudata()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updatecategory;
        if (this.state.iscreate)
            baseurl = myconfig.createcategory;
        axios.post(baseurl, {
            id: this.state.id,
            catagory: this.state.catagory,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.showcu(event,value.cat_name,value.id)})}>Manage Catagory</a> |<a href="#">Delete</a></td>
                    <td>{value.cat_name}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event,iselect) => {
        if(iselect){
            var name = event.name;
            var value = event.value;
        }else{
            event.preventDefault();
            var name = event.target.name;
            var value = event.target.value;
        }
        let temp = this.state.menus;
        console.log(temp)
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name_en.toUpperCase().includes(value.toUpperCase())||
                    v.menu_types.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filteredMenus: temp,
        })
    }
    render() {
        return (
            <>
                <Nav type="menumatagory"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Menu Category Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Menu Catagory</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Catagory</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default MenuCatagory;
