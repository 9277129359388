import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';
import StockitemSelect from "./Select/StockitemSelect";

let source = axios.CancelToken.source();

class StockWastage extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            id:-1,

            //modal
            cushow: false,
            iscreate: true,

            wastage_date: '',
            quantity: '',
            remark: '',
            isExpire:"0",

            stock_item_id: "-1",
            stock_item_obj:null,
        }
    }
    componentDidMount() {
        this.fetcheddata();
    }
    stockitem_selected=(id)=> {
        this.setState({
            stock_item_id: id,
        })
    }
    successumit=()=> {
        this.setState({
            price:'',
            indate:'',
            quantity:'',
            remark:'',
            cushow: false,
        }, () => this.fetcheddata())
    }
    fetcheddata = () => {
        let baseurl = myconfig.getstockwastages;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count=count+1
                let expire="No";
                if(value.isExpire)
                    expire="Yes"
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a> |<a href="#">Delete</a></td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.siunit}</td>
                    <td>{value.type}</td>
                    <td>{value.wastage_date}</td>
                    <td>{value.quantity}</td>
                    <td>{expire}</td>
                    <td>{value.remark}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Stock Wastage</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <StockitemSelect stock_item_obj={this.state.stock_item_obj} stockitem_selected={this.stockitem_selected}/>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Wastage Date</label>
                                                <input  className="form-control" type="date" name="wastage_date" onChange={this.inputmgt} value={this.state.wastage_date}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Quantity</label>
                                                <input  className="form-control" type="text" name="quantity" onChange={this.inputmgt} value={this.state.quantity}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Is Expire</label>
                                                <select  className="form-control" name="isExpire" onChange={this.inputmgt} value={this.state.isExpire}>
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Remark</label>
                                                <textarea rows={5}  className="form-control" name="remark" onChange={this.inputmgt}>
                                                    {this.state.remark}
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow: true,
                iscreate: iscreate,
                wastage_date: '',
                quantity: '',
                remark: '',
                isExpire:false,
                stock_item_id: "-1",
                stock_item_obj:null,
            })
        }
        else {
            let baseurl = myconfig.getstockwastage;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    /*  window.localStorage.setItem("fas_sid", null);
                      window.localStorage.setItem("fas_username", null);
                      window.localStorage.setItem("fas_name", null);
                      var win = window.open(redirecttologin, "_self");*/
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            cushow: true,
                            id:id,
                            iscreate: iscreate,
                            wastage_date: res.data.data.wastage_date,
                            quantity: res.data.data.quantity,
                            remark: res.data.data.remark,
                            isExpire: res.data.data.isExpire,
                            stock_item_id: res.data.data.stock_item_id,
                            stock_item_obj: res.data.data,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.stockwastageupdate;
        if (this.state.iscreate)
            baseurl = myconfig.stockwastagecreate;
        axios.post(baseurl, {
            id: this.state.id,
            wastage_date: this.state.wastage_date,
            quantity: this.state.quantity,
            remark: this.state.remark,
            isExpire: this.state.isExpire,
            stock_item_id: this.state.stock_item_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.successumit()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    render() {
        return (
            <>
                <Nav type="stockwastage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Wastage Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Stock Wastage</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New Stock Wastage</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Item Name (EN) </th>
                                                    <th>Item Name (AMH) </th>
                                                    <th>SI Unit </th>
                                                    <th>Type</th>
                                                    <th>Wastage-Date</th>
                                                    <th>Quantity</th>
                                                    <th>IS Expire</th>
                                                    <th>Remark</th>
                                                </tr>
                                                </thead>
                                                <tbody>{this.tabledata()}</tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default StockWastage;
