import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class UserAccess1 extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            obj: [],
            objload: false,
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let baseurl = myconfig.getusers;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count=count+1
                let isactive="Yes";
                let isadmin="No";
                if(value.isactive=="0")
                    isactive="No";
                if(value.isadmin=="1")
                    isadmin="Yes";

                return (<tr>
                    <td>{count}</td>
                    <td>
                        <a href={myconfig.useraccess2+"?id="+value.id}>Manage Access</a>
                    </td>
                    <td>{value.username}</td>
                    <td>{value.name}</td>
                    <td>{value.email}</td>
                    <td>{isadmin}</td>
                    <td>{isactive}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    render() {
        return (
            <>
                <Nav type="useraccess1"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">User Access's Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">User Access</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Username</th>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Is Admin</th>
                                                    <th>Is Active</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default UserAccess1;
