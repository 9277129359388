export const myconfig =
    {
            /* "siunitcreate": "http://localhost/mycafe2api/public/api/siunit/create",
             "siunitupdate": "http://localhost/mycafe2api/public/api/siunit/update",
             "siunitgetsiunits": "http://localhost/mycafe2api/public/api/siunit/getsiunits",
             "siunitgetsiunit": "http://localhost/mycafe2api/public/api/siunit/getsiunit",

             "departmentcreate": "http://localhost/mycafe2api/public/api/department/create",
             "departmentupdate": "http://localhost/mycafe2api/public/api/department/update",
             "departmentgetdepartments": "http://localhost/mycafe2api/public/api/department/getdepartments",
             "departmentgetdepartment": "http://localhost/mycafe2api/public/api/department/getdepartment",

             "employeecreate": "http://localhost/mycafe2api/public/api/employee/create",
             "employeeupdate": "http://localhost/mycafe2api/public/api/employee/update",
             "employeegetemployees": "http://localhost/mycafe2api/public/api/employee/getemployees",
             "employeegetemployee": "http://localhost/mycafe2api/public/api/employee/getemployee",

             "stockitemcreate": "http://localhost/mycafe2api/public/api/stockitem/create",
             "stockitemupdate": "http://localhost/mycafe2api/public/api/stockitem/update",
             "stockitemgetstockitems": "http://localhost/mycafe2api/public/api/stockitem/getstockitems",
             "stockitemgetstockitem": "http://localhost/mycafe2api/public/api/stockitem/getstockitem",

             "stockincreate": "http://localhost/mycafe2api/public/api/stockin/create",
             "stockinupdate": "http://localhost/mycafe2api/public/api/stockin/update",
             "stockingetstockins": "http://localhost/mycafe2api/public/api/stockin/getstockins",
             "stockingetstockin": "http://localhost/mycafe2api/public/api/stockin/getstockin",

             "stockoutcreate": "http://localhost/mycafe2api/public/api/stockout/create",
             "stockoutupdate": "http://localhost/mycafe2api/public/api/stockout/update",
             "stockoutgetstockouts": "http://localhost/mycafe2api/public/api/stockout/getstockouts",
             "stockoutgetstockout": "http://localhost/mycafe2api/public/api/stockout/getstockout",

             "stockwastagecreate": "http://localhost/mycafe2api/public/api/stockwastage/create",
             "stockwastageupdate": "http://localhost/mycafe2api/public/api/stockwastage/update",
             "getstockwastages": "http://localhost/mycafe2api/public/api/stockwastage/getstockwastages",
             "getstockwastage": "http://localhost/mycafe2api/public/api/stockwastage/getstockwastage",

             "reportgetstockin": "http://localhost/mycafe2api/public/api/report/getstockin",
             "reportgetstockout": "http://localhost/mycafe2api/public/api/report/getstockout",
             "reportgetstockwastage": "http://localhost/mycafe2api/public/api/report/getstockwastage",
             "reportgetstockitem": "http://localhost/mycafe2api/public/api/report/getstockitem",
             "rgetorders": "http://localhost/mycafe2api/public/api/report/rgetorders",

             "createuser": "http://localhost/mycafe2api/public/api/user/createuser",
             "updateuser": "http://localhost/mycafe2api/public/api/user/updateuser",
             "changepwduser": "http://localhost/mycafe2api/public/api/user/changepwduser",
             "getusers": "http://localhost/mycafe2api/public/api/user/getusers",
             "getuser": "http://localhost/mycafe2api/public/api/user/getuser",
             "getuseraccess": "http://localhost/mycafe2api/public/api/user/getuseraccess",
             "updateuseraccess": "http://localhost/mycafe2api/public/api/user/updateuseraccess",
             "login": "http://localhost/mycafe2api/public/api/user/login",
             "checksession": "http://localhost/mycafe2api/public/api/user/checksession",
             "logout": "http://localhost/mycafe2api/public/api/user/logout",
             "getuasession": "http://localhost/mycafe2api/public/api/user/getuasession",
             "getusertable": "http://localhost/mycafe2api/public/api/user/getusertable",
             "toggleusertable": "http://localhost/mycafe2api/public/api/user/toggleusertable",

             "createobjuser": "http://localhost/mycafe2api/public/api/objuser/createobjuser",
             "updateobjuser": "http://localhost/mycafe2api/public/api/objuser/updateobjuser",
             "getobjusers": "http://localhost/mycafe2api/public/api/objuser/getobjusers",
             "getobjuser": "http://localhost/mycafe2api/public/api/objuser/getobjuser",

             "createmenutype": "http://localhost/mycafe2api/public/api/menutype/createmenutype",
             "updatemenutype": "http://localhost/mycafe2api/public/api/menutype/updatemenutype",
             "getmenutype": "http://localhost/mycafe2api/public/api/menutype/getmenutype",
             "getmenutypes": "http://localhost/mycafe2api/public/api/menutype/getmenutypes",

             "createcatagory": "http://localhost/mycafe2api/public/api/catagory/createcatagory",
             "updatecatagory": "http://localhost/mycafe2api/public/api/catagory/updatecatagory",
             "getcatgorys": "http://localhost/mycafe2api/public/api/catagory/getcatgorys",
             "getcatgory": "http://localhost/mycafe2api/public/api/catagory/getcatgory",

             "createmenu": "http://localhost/mycafe2api/public/api/menu/createmenu",
             "updatemenu": "http://localhost/mycafe2api/public/api/menu/updatemenu",
             "getmenus": "http://localhost/mycafe2api/public/api/menu/getmenus",
             "getmenu": "http://localhost/mycafe2api/public/api/menu/getmenu",

             "createtable": "http://localhost/mycafe2api/public/api/table/createtable",
             "updatetable": "http://localhost/mycafe2api/public/api/table/updatetable",
             "gettables": "http://localhost/mycafe2api/public/api/table/gettables",
             "gettable": "http://localhost/mycafe2api/public/api/table/gettable",

             "togglemenucat": "http://localhost/mycafe2api/public/api/menucatagory/togglemenucat",
             "getmenucat": "http://localhost/mycafe2api/public/api/menucatagory/getmenucat",
             "getmenucat_for_order": "http://localhost/mycafe2api/public/api/menucatagory/getmenucat_for_order",

             "createorder": "http://localhost/mycafe2api/public/api/order/createorder",
             "updateorder": "http://localhost/mycafe2api/public/api/order/updateorder",
             "deleteorder": "http://localhost/mycafe2api/public/api/order/deleteorder",
             "getorder": "http://localhost/mycafe2api/public/api/order/getorder",
             "getorders": "http://localhost/mycafe2api/public/api/order/getorders",
             "getstatuitems": "http://localhost/mycafe2api/public/api/order/getstatuitems",
             "getorderlogs": "http://localhost/mycafe2api/public/api/order/getorderlogs",
             "getordersforprocesschefe": "http://localhost/mycafe2api/public/api/order/getordersforprocesschefe",
             "getordersforprocessbeve": "http://localhost/mycafe2api/public/api/order/getordersforprocessbeve",
             "toggleproccessnext": "http://localhost/mycafe2api/public/api/order/toggleproccessnext",
             "gettableforfinance": "http://localhost/mycafe2api/public/api/order/gettableforfinance",
             "tofinance": "http://localhost/mycafe2api/public/api/order/tofinance",
             "getordersforfinance": "http://localhost/mycafe2api/public/api/order/getordersforfinance",
             "gettableforpayment": "http://localhost/mycafe2api/public/api/order/gettableforpayment",
             "orderpayment": "http://localhost/mycafe2api/public/api/order/orderpayment",
             "getpaymentdetbytable": "http://localhost/mycafe2api/public/api/order/getpaymentdetbytable",
             "gettablebyuser": "http://localhost/mycafe2api/public/api/order/gettablebyuser",
             "getordersforscreen": "http://localhost/mycafe2api/public/api/order/getordersforscreen",

             "get_purchase_req": "http://localhost/mycafe2api/public/api/stockin/get_purchase_req",
             "get_single_purchase_req": "http://localhost/mycafe2api/public/api/stockin/get_single_purchase_req",
             "create_purchase_order": "http://localhost/mycafe2api/public/api/stockin/create_purchase_order",
             "edit_purchase_order": "http://localhost/mycafe2api/public/api/stockin/edit_purchase_order",
             "delete_purchase_order": "http://localhost/mycafe2api/public/api/stockin/delete_purchase_order",
             "approve_reject_purchase_order": "http://localhost/mycafe2api/public/api/stockin/approve_reject_purchase_order",

             "get_purchase_req_approved": "http://localhost/mycafe2api/public/api/stockin/get_purchase_req_approved",
             "get_single_purchase_req_approved": "http://localhost/mycafe2api/public/api/stockin/get_single_purchase_req_approved",
             "purchase_purchased_not_purchase": "http://localhost/mycafe2api/public/api/stockin/purchase_purchased_not_purchase",
             "get_purchase_req_reject": "http://localhost/mycafe2api/public/api/stockin/get_purchase_req_reject",
             "get_single_purchase_req_reject": "http://localhost/mycafe2api/public/api/stockin/get_single_purchase_req_reject",
             "get_purchase_purchased": "http://localhost/mycafe2api/public/api/stockin/get_purchase_purchased",
             "get_single_purchase_purchased": "http://localhost/mycafe2api/public/api/stockin/get_single_purchase_purchased",
             "approve_reject_purchase_purchased_order": "http://localhost/mycafe2api/public/api/stockin/approve_reject_purchase_purchased_order",
             "get_purchase_not_purchased": "http://localhost/mycafe2api/public/api/stockin/get_purchase_not_purchased",
             "get_single_not_purchase_purchased": "http://localhost/mycafe2api/public/api/stockin/get_single_not_purchase_purchased",
             "get_purchase_purchased_approved": "http://localhost/mycafe2api/public/api/stockin/get_purchase_purchased_approved",
             "get_single_purchase_purchased_approved": "http://localhost/mycafe2api/public/api/stockin/get_single_purchase_purchased_approved",
             "purchase_recived": "http://localhost/mycafe2api/public/api/stockin/purchase_recived",
             "get_purchase_received": "http://localhost/mycafe2api/public/api/stockin/get_purchase_received",

             "get_store_out_req": "http://localhost/mycafe2api/public/api/stockout/get_store_out_req",
             "get_single_store_out_req": "http://localhost/mycafe2api/public/api/stockout/get_single_store_out_req",
             "create_stockout_request_order": "http://localhost/mycafe2api/public/api/stockout/create_stockout_request_order",
             "edit_stockout_request_order": "http://localhost/mycafe2api/public/api/stockout/edit_stockout_request_order",
             "delete_stockout_request_order": "http://localhost/mycafe2api/public/api/stockout/delete_stockout_request_order",
             "approve_reject_stockout_request": "http://localhost/mycafe2api/public/api/stockout/approve_reject_stockout_request",
             "get_stock_out_approved": "http://localhost/mycafe2api/public/api/stockout/get_stock_out_approved",
             "get_single_stock_out_approved": "http://localhost/mycafe2api/public/api/stockout/get_single_stock_out_approved",
             "submitted_stockout_request": "http://localhost/mycafe2api/public/api/stockout/submitted_stockout_request",
             "get_stock_out_rejected": "http://localhost/mycafe2api/public/api/stockout/get_stock_out_rejected",
             "get_single_stock_out_rejected": "http://localhost/mycafe2api/public/api/stockout/get_single_stock_out_rejected",
             "get_stock_out_submitted": "http://localhost/mycafe2api/public/api/stockout/get_stock_out_submitted",
             "get_single_stock_out_submitted": "http://localhost/mycafe2api/public/api/stockout/get_single_stock_out_submitted",
             "received_stockout_request": "http://localhost/mycafe2api/public/api/stockout/received_stockout_request",
             "get_stock_out_received": "http://localhost/mycafe2api/public/api/stockout/get_stock_out_received",
             "get_stock_wastage_manage": "http://localhost/mycafe2api/public/api/stockwastage/get_stock_wastage_manage",
             "get_single_stock_wastage_manage": "http://localhost/mycafe2api/public/api/stockwastage/get_single_stock_wastage_manage",
             "create_stock_wastage": "http://localhost/mycafe2api/public/api/stockwastage/create_stock_wastage",
             "get_store_wastage_req": "http://localhost/mycafe2api/public/api/stockwastage/get_store_wastage_req",
             "get_store_single_wastage_req": "http://localhost/mycafe2api/public/api/stockwastage/get_store_single_wastage_req",
             "edit_stock_wastage": "http://localhost/mycafe2api/public/api/stockwastage/edit_stock_wastage",
             "delete_stock_wastage": "http://localhost/mycafe2api/public/api/stockwastage/delete_stock_wastage",
             "approve_reject_stock_wastage_request": "http://localhost/mycafe2api/public/api/stockwastage/approve_reject_stock_wastage_request",
             "get_stock_wastage_approved": "http://localhost/mycafe2api/public/api/stockwastage/get_stock_wastage_approved",
             "get_stock_single_wastage_approved": "http://localhost/mycafe2api/public/api/stockwastage/get_stock_single_wastage_approved",
             "get_stock_wastage_reject": "http://localhost/mycafe2api/public/api/stockwastage/get_stock_wastage_reject",
             "get_stock_single_wastage_reject": "http://localhost/mycafe2api/public/api/stockwastage/get_stock_single_wastage_reject",
             "report_daily_sale": "http://localhost/mycafe2api/public/api/departmentuser/report_daily_sale",
             "report_daily_sale_2": "http://localhost/mycafe2api/public/api/departmentuser/report_daily_sale_2",
             "r_order_for_chafe": "http://localhost/mycafe2api/public/api/departmentuser/r_order_for_chafe",
             "r_order_for_waiter": "http://localhost/mycafe2api/public/api/departmentuser/r_order_for_waiter",
             "r_order_for_barman": "http://localhost/mycafe2api/public/api/departmentuser/r_order_for_barman",
             "report_order_by": "http://localhost/mycafe2api/public/api/departmentuser/report_order_by",
             "report_served_by": "http://localhost/mycafe2api/public/api/departmentuser/report_served_by",
             "get_usertimes": "http://localhost/mycafe2api/public/api/user/get_usertimes",
             "update_usertime": "http://localhost/mycafe2api/public/api/user/update_usertime",
             "changepwd_by_user": "http://localhost/mycafe2api/public/api/user/changepwd_by_user",
             "auth_2": "http://localhost/mycafe2api/public/api/user/auth_2",
             "login_pin": "http://localhost/mycafe2api/public/api/user/login_pin",
             "report_stock_in": "http://localhost/mycafe2api/public/api/departmentuser/report_stock_in",
             "report_stock_out": "http://localhost/mycafe2api/public/api/departmentuser/report_stock_out",
             "report_stock_balance": "http://localhost/mycafe2api/public/api/departmentuser/report_stock_balance",
             "report_stock_in_sum": "http://localhost/mycafe2api/public/api/departmentuser/report_stock_in_sum",
             "report_stock_out_sum": "http://localhost/mycafe2api/public/api/departmentuser/report_stock_out_sum",
             "d_daily_activity": "http://localhost/mycafe2api/public/api/dashboard/d_daily_activity",
             "d_yesterday_activity": "http://localhost/mycafe2api/public/api/dashboard/d_yesterday_activity",
             "r_waiting_time": "http://localhost/mycafe2api/public/api/departmentuser/r_waiting_time",
             "r_notcom_order": "http://localhost/mycafe2api/public/api/departmentuser/r_notcom_order",
             "r_removed_order": "http://localhost/mycafe2api/public/api/departmentuser/r_removed_order",
             "getstockitems_for_pur": "http://localhost/mycafe2api/public/api/stockitem/getstockitems_for_pur",


             "redirecttologin": "http://localhost:3000/login",
             "redirect": "http://localhost:3000",
             "redirecttoauth_2": "http://localhost:3000/auth_2",*/

            "siunitcreate": "https://api.guansaplc.com//api/siunit/create",
            "siunitupdate": "https://api.guansaplc.com/api/siunit/update",
            "siunitgetsiunits": "https://api.guansaplc.com/api/siunit/getsiunits",
            "siunitgetsiunit": "https://api.guansaplc.com/api/siunit/getsiunit",

            "departmentcreate": "https://api.guansaplc.com/api/department/create",
            "departmentupdate": "https://api.guansaplc.com/api/department/update",
            "departmentgetdepartments": "https://api.guansaplc.com/api/department/getdepartments",
            "departmentgetdepartment": "https://api.guansaplc.com/api/department/getdepartment",

            "employeecreate": "https://api.guansaplc.com/api/employee/create",
            "employeeupdate": "https://api.guansaplc.com/api/employee/update",
            "employeegetemployees": "https://api.guansaplc.com/api/employee/getemployees",
            "employeegetemployee": "https://api.guansaplc.com/api/employee/getemployee",

            "stockitemcreate": "https://api.guansaplc.com/api/stockitem/create",
            "stockitemupdate": "https://api.guansaplc.com/api/stockitem/update",
            "stockitemgetstockitems": "https://api.guansaplc.com/api/stockitem/getstockitems",
            "stockitemgetstockitem": "https://api.guansaplc.com/api/stockitem/getstockitem",

            "stockincreate": "https://api.guansaplc.com/api/stockin/create",
            "stockinupdate": "https://api.guansaplc.com/api/stockin/update",
            "stockingetstockins": "https://api.guansaplc.com/api/stockin/getstockins",
            "stockingetstockin": "https://api.guansaplc.com/api/stockin/getstockin",

            "stockoutcreate": "https://api.guansaplc.com/api/stockout/create",
            "stockoutupdate": "https://api.guansaplc.com/api/stockout/update",
            "stockoutgetstockouts": "https://api.guansaplc.com/api/stockout/getstockouts",
            "stockoutgetstockout": "https://api.guansaplc.com/api/stockout/getstockout",

            "stockwastagecreate": "https://api.guansaplc.com/api/stockwastage/create",
            "stockwastageupdate": "https://api.guansaplc.com/api/stockwastage/update",
            "getstockwastages": "https://api.guansaplc.com/api/stockwastage/getstockwastages",
            "getstockwastage": "https://api.guansaplc.com/api/stockwastage/getstockwastage",

            "reportgetstockin": "https://api.guansaplc.com/api/report/getstockin",
            "reportgetstockout": "https://api.guansaplc.com/api/report/getstockout",
            "reportgetstockwastage": "https://api.guansaplc.com/api/report/getstockwastage",
            "reportgetstockitem": "https://api.guansaplc.com/api/report/getstockitem",
            "rgetorders": "https://api.guansaplc.com/api/report/rgetorders",

            "createuser": "https://api.guansaplc.com/api/user/createuser",
            "updateuser": "https://api.guansaplc.com/api/user/updateuser",
            "changepwduser": "https://api.guansaplc.com/api/user/changepwduser",
            "getusers": "https://api.guansaplc.com/api/user/getusers",
            "getuser": "https://api.guansaplc.com/api/user/getuser",
            "getuseraccess": "https://api.guansaplc.com/api/user/getuseraccess",
            "updateuseraccess": "https://api.guansaplc.com/api/user/updateuseraccess",
            "login": "https://api.guansaplc.com/api/user/login",
            "checksession": "https://api.guansaplc.com/api/user/checksession",
            "logout": "https://api.guansaplc.com/api/user/logout",
            "getuasession": "https://api.guansaplc.com/api/user/getuasession",
            "getusertable": "https://api.guansaplc.com/api/user/getusertable",
            "toggleusertable": "https://api.guansaplc.com/api/user/toggleusertable",

            "createobjuser": "https://api.guansaplc.com/api/objuser/createobjuser",
            "updateobjuser": "https://api.guansaplc.com/api/objuser/updateobjuser",
            "getobjusers": "https://api.guansaplc.com/api/objuser/getobjusers",
            "getobjuser": "https://api.guansaplc.com/api/objuser/getobjuser",

            "createmenutype": "https://api.guansaplc.com/api/menutype/createmenutype",
            "updatemenutype": "https://api.guansaplc.com/api/menutype/updatemenutype",
            "getmenutype": "https://api.guansaplc.com/api/menutype/getmenutype",
            "getmenutypes": "https://api.guansaplc.com/api/menutype/getmenutypes",

            "createcatagory": "https://api.guansaplc.com/api/catagory/createcatagory",
            "updatecatagory": "https://api.guansaplc.com/api/catagory/updatecatagory",
            "getcatgorys": "https://api.guansaplc.com/api/catagory/getcatgorys",
            "getcatgory": "https://api.guansaplc.com/api/catagory/getcatgory",

            "createmenu": "https://api.guansaplc.com/api/menu/createmenu",
            "updatemenu": "https://api.guansaplc.com/api/menu/updatemenu",
            "getmenus": "https://api.guansaplc.com/api/menu/getmenus",
            "getmenu": "https://api.guansaplc.com/api/menu/getmenu",

            "createtable": "https://api.guansaplc.com/api/table/createtable",
            "updatetable": "https://api.guansaplc.com/api/table/updatetable",
            "gettables": "https://api.guansaplc.com/api/table/gettables",
            "gettable": "https://api.guansaplc.com/api/table/gettable",

            "togglemenucat": "https://api.guansaplc.com/api/menucatagory/togglemenucat",
            "getmenucat": "https://api.guansaplc.com/api/menucatagory/getmenucat",
            "getmenucat_for_order": "https://api.guansaplc.com/api/menucatagory/getmenucat_for_order",

            "createorder": "https://api.guansaplc.com/api/order/createorder",
            "updateorder": "https://api.guansaplc.com/api/order/updateorder",
            "deleteorder": "https://api.guansaplc.com/api/order/deleteorder",
            "getorder": "https://api.guansaplc.com/api/order/getorder",
            "getorders": "https://api.guansaplc.com/api/order/getorders",
            "getstatuitems": "https://api.guansaplc.com/api/order/getstatuitems",
            "getorderlogs": "https://api.guansaplc.com/api/order/getorderlogs",
            "getordersforprocesschefe": "https://api.guansaplc.com/api/order/getordersforprocesschefe",
            "getordersforprocessbeve": "https://api.guansaplc.com/api/order/getordersforprocessbeve",
            "toggleproccessnext": "https://api.guansaplc.com/api/order/toggleproccessnext",
            "gettableforfinance": "https://api.guansaplc.com/api/order/gettableforfinance",
            "tofinance": "https://api.guansaplc.com/api/order/tofinance",
            "getordersforfinance": "https://api.guansaplc.com/api/order/getordersforfinance",
            "gettableforpayment": "https://api.guansaplc.com/api/order/gettableforpayment",
            "orderpayment": "https://api.guansaplc.com/api/order/orderpayment",
            "getpaymentdetbytable": "https://api.guansaplc.com/api/order/getpaymentdetbytable",
            "gettablebyuser": "https://api.guansaplc.com/api/order/gettablebyuser",
            "getordersforscreen": "http://api.guansaplc.com/api/order/getordersforscreen",

            "get_purchase_req": "https://api.guansaplc.com/api/stockin/get_purchase_req",
            "get_single_purchase_req": "https://api.guansaplc.com/api/stockin/get_single_purchase_req",
            "create_purchase_order": "https://api.guansaplc.com/api/stockin/create_purchase_order",
            "edit_purchase_order": "https://api.guansaplc.com/api/stockin/edit_purchase_order",
            "delete_purchase_order": "https://api.guansaplc.com/api/stockin/delete_purchase_order",
            "approve_reject_purchase_order": "https://api.guansaplc.com/api/stockin/approve_reject_purchase_order",

            "get_purchase_req_approved": "https://api.guansaplc.com/api/stockin/get_purchase_req_approved",
            "get_single_purchase_req_approved": "https://api.guansaplc.com/api/stockin/get_single_purchase_req_approved",
            "purchase_purchased_not_purchase": "https://api.guansaplc.com/api/stockin/purchase_purchased_not_purchase",
            "get_purchase_req_reject": "https://api.guansaplc.com/api/stockin/get_purchase_req_reject",
            "get_single_purchase_req_reject": "https://api.guansaplc.com/api/stockin/get_single_purchase_req_reject",
            "get_purchase_purchased": "https://api.guansaplc.com/api/stockin/get_purchase_purchased",
            "get_single_purchase_purchased": "https://api.guansaplc.com/api/stockin/get_single_purchase_purchased",
            "approve_reject_purchase_purchased_order": "https://api.guansaplc.com/api/stockin/approve_reject_purchase_purchased_order",
            "get_purchase_not_purchased": "https://api.guansaplc.com/api/stockin/get_purchase_not_purchased",
            "get_single_not_purchase_purchased": "https://api.guansaplc.com/api/stockin/get_single_not_purchase_purchased",
            "get_purchase_purchased_approved": "https://api.guansaplc.com/api/stockin/get_purchase_purchased_approved",
            "get_single_purchase_purchased_approved": "https://api.guansaplc.com/api/stockin/get_single_purchase_purchased_approved",
            "purchase_recived": "https://api.guansaplc.com/api/stockin/purchase_recived",
            "get_purchase_received": "https://api.guansaplc.com/api/stockin/get_purchase_received",

            "get_store_out_req": "https://api.guansaplc.com/api/stockout/get_store_out_req",
            "get_single_store_out_req": "https://api.guansaplc.com/api/stockout/get_single_store_out_req",
            "create_stockout_request_order": "https://api.guansaplc.com/api/stockout/create_stockout_request_order",
            "edit_stockout_request_order": "https://api.guansaplc.com/api/stockout/edit_stockout_request_order",
            "delete_stockout_request_order": "https://api.guansaplc.com/api/stockout/delete_stockout_request_order",
            "approve_reject_stockout_request": "https://api.guansaplc.com/api/stockout/approve_reject_stockout_request",
            "get_stock_out_approved": "https://api.guansaplc.com/api/stockout/get_stock_out_approved",
            "get_single_stock_out_approved": "https://api.guansaplc.com/api/stockout/get_single_stock_out_approved",
            "submitted_stockout_request": "https://api.guansaplc.com/api/stockout/submitted_stockout_request",
            "get_stock_out_rejected": "https://api.guansaplc.com/api/stockout/get_stock_out_rejected",
            "get_single_stock_out_rejected": "https://api.guansaplc.com/api/stockout/get_single_stock_out_rejected",
            "get_stock_out_submitted": "https://api.guansaplc.com/api/stockout/get_stock_out_submitted",
            "get_single_stock_out_submitted": "https://api.guansaplc.com/api/stockout/get_single_stock_out_submitted",
            "received_stockout_request": "https://api.guansaplc.com/api/stockout/received_stockout_request",
            "get_stock_out_received": "https://api.guansaplc.com/api/stockout/get_stock_out_received",
            "get_stock_wastage_manage": "https://api.guansaplc.com/api/stockwastage/get_stock_wastage_manage",
            "get_single_stock_wastage_manage": "https://api.guansaplc.com/api/stockwastage/get_single_stock_wastage_manage",
            "create_stock_wastage": "https://api.guansaplc.com/api/stockwastage/create_stock_wastage",
            "get_store_wastage_req": "https://api.guansaplc.com/api/stockwastage/get_store_wastage_req",
            "get_store_single_wastage_req": "https://api.guansaplc.com/api/stockwastage/get_store_single_wastage_req",
            "edit_stock_wastage": "https://api.guansaplc.com/api/stockwastage/edit_stock_wastage",
            "delete_stock_wastage": "https://api.guansaplc.com/api/stockwastage/delete_stock_wastage",
            "approve_reject_stock_wastage_request": "https://api.guansaplc.com/api/stockwastage/approve_reject_stock_wastage_request",
            "get_stock_wastage_approved": "https://api.guansaplc.com/api/stockwastage/get_stock_wastage_approved",
            "get_stock_single_wastage_approved": "https://api.guansaplc.com/api/stockwastage/get_stock_single_wastage_approved",
            "get_stock_wastage_reject": "https://api.guansaplc.com/api/stockwastage/get_stock_wastage_reject",
            "get_stock_single_wastage_reject": "https://api.guansaplc.com/api/stockwastage/get_stock_single_wastage_reject",
            "report_daily_sale": "https://api.guansaplc.com/api/departmentuser/report_daily_sale",
            "report_daily_sale_2": "https://api.guansaplc.com/api/departmentuser/report_daily_sale_2",
            "r_order_for_chafe": "https://api.guansaplc.com/api/departmentuser/r_order_for_chafe",
            "r_order_for_waiter": "https://api.guansaplc.com/api/departmentuser/r_order_for_waiter",
            "r_order_for_barman": "https://api.guansaplc.com/api/departmentuser/r_order_for_barman",
            "report_order_by": "https://api.guansaplc.com/api/departmentuser/report_order_by",
            "report_served_by": "https://api.guansaplc.com/api/departmentuser/report_served_by",
            "get_usertimes": "https://api.guansaplc.com/api/user/get_usertimes",
            "update_usertime": "https://api.guansaplc.com/api/user/update_usertime",
            "changepwd_by_user": "https://api.guansaplc.com/api/user/changepwd_by_user",
            "auth_2": "https://api.guansaplc.com/api/user/auth_2",
            "login_pin": "https://api.guansaplc.com/api/user/login_pin",
            "report_stock_in": "https://api.guansaplc.com/api/departmentuser/report_stock_in",
            "report_stock_out": "https://api.guansaplc.com/api/departmentuser/report_stock_out",
            "report_stock_balance": "https://api.guansaplc.com/api/departmentuser/report_stock_balance",
            "report_stock_in_sum": "https://api.guansaplc.com/api/departmentuser/report_stock_in_sum",
            "report_stock_out_sum": "https://api.guansaplc.com/api/departmentuser/report_stock_out_sum",
            "d_daily_activity": "https://api.guansaplc.com/api/dashboard/d_daily_activity",
            "d_yesterday_activity": "https://api.guansaplc.com/api/dashboard/d_yesterday_activity",
            "r_waiting_time": "https://api.guansaplc.com/api/departmentuser/r_waiting_time",
            "r_notcom_order": "https://api.guansaplc.com/api/departmentuser/r_notcom_order",
            "r_removed_order": "https://api.guansaplc.com/api/departmentuser/r_removed_order",
            "getstockitems_for_pur": "https://api.guansaplc.com/api/stockitem/getstockitems_for_pur",

            "redirecttologin": "https://portal.guansaplc.com/login",
            "redirect": "https://portal.guansaplc.com",
            "redirecttoauth_2": "https://portal.guansaplc.com/auth_2",

            /*  "siunitcreate": "https://test.api.guansaplc.com/api/siunit/create",
              "siunitupdate": "https://test.api.guansaplc.com/api/siunit/update",
              "siunitgetsiunits": "https://test.api.guansaplc.com/api/siunit/getsiunits",
              "siunitgetsiunit": "https://test.api.guansaplc.com/api/siunit/getsiunit",

              "departmentcreate": "https://test.api.guansaplc.com/api/department/create",
              "departmentupdate": "https://test.api.guansaplc.com/api/department/update",
              "departmentgetdepartments": "https://test.api.guansaplc.com/api/department/getdepartments",
              "departmentgetdepartment": "https://test.api.guansaplc.com/api/department/getdepartment",

              "employeecreate": "https://test.api.guansaplc.com/api/employee/create",
              "employeeupdate": "https://test.api.guansaplc.com/api/employee/update",
              "employeegetemployees": "https://test.api.guansaplc.com/api/employee/getemployees",
              "employeegetemployee": "https://test.api.guansaplc.com/api/employee/getemployee",

              "stockitemcreate": "https://test.api.guansaplc.com/api/stockitem/create",
              "stockitemupdate": "https://test.api.guansaplc.com/api/stockitem/update",
              "stockitemgetstockitems": "https://test.api.guansaplc.com/api/stockitem/getstockitems",
              "stockitemgetstockitem": "https://test.api.guansaplc.com/api/stockitem/getstockitem",

              "stockincreate": "https://test.api.guansaplc.com/api/stockin/create",
              "stockinupdate": "https://test.api.guansaplc.com/api/stockin/update",
              "stockingetstockins": "https://test.api.guansaplc.com/api/stockin/getstockins",
              "stockingetstockin": "https://test.api.guansaplc.com/api/stockin/getstockin",

              "stockoutcreate": "https://test.api.guansaplc.com/api/stockout/create",
              "stockoutupdate": "https://test.api.guansaplc.com/api/stockout/update",
              "stockoutgetstockouts": "https://test.api.guansaplc.com/api/stockout/getstockouts",
              "stockoutgetstockout": "https://test.api.guansaplc.com/api/stockout/getstockout",

              "stockwastagecreate": "https://test.api.guansaplc.com/api/stockwastage/create",
              "stockwastageupdate": "https://test.api.guansaplc.com/api/stockwastage/update",
              "getstockwastages": "https://test.api.guansaplc.com/api/stockwastage/getstockwastages",
              "getstockwastage": "https://test.api.guansaplc.com/api/stockwastage/getstockwastage",

              "reportgetstockin": "https://test.api.guansaplc.com/api/report/getstockin",
              "reportgetstockout": "https://test.api.guansaplc.com/api/report/getstockout",
              "reportgetstockwastage": "https://test.api.guansaplc.com/api/report/getstockwastage",
              "reportgetstockitem": "https://test.api.guansaplc.com/api/report/getstockitem",
              "rgetorders": "https://test.api.guansaplc.com/api/report/rgetorders",

              "createuser": "https://test.api.guansaplc.com/api/user/createuser",
              "updateuser": "https://test.api.guansaplc.com/api/user/updateuser",
              "changepwduser": "https://test.api.guansaplc.com/api/user/changepwduser",
              "getusers": "https://test.api.guansaplc.com/api/user/getusers",
              "getuser": "https://test.api.guansaplc.com/api/user/getuser",
              "getuseraccess": "https://test.api.guansaplc.com/api/user/getuseraccess",
              "updateuseraccess": "https://test.api.guansaplc.com/api/user/updateuseraccess",
              "login": "https://test.api.guansaplc.com/api/user/login",
              "checksession": "https://test.api.guansaplc.com/api/user/checksession",
              "logout": "https://test.api.guansaplc.com/api/user/logout",
              "getuasession": "https://test.api.guansaplc.com/api/user/getuasession",
              "getusertable": "https://test.api.guansaplc.com/api/user/getusertable",
              "toggleusertable": "https://test.api.guansaplc.com/api/user/toggleusertable",

              "createobjuser": "https://test.api.guansaplc.com/api/objuser/createobjuser",
              "updateobjuser": "https://test.api.guansaplc.com/api/objuser/updateobjuser",
              "getobjusers": "https://test.api.guansaplc.com/api/objuser/getobjusers",
              "getobjuser": "https://test.api.guansaplc.com/api/objuser/getobjuser",

              "createmenutype": "https://test.api.guansaplc.com/api/menutype/createmenutype",
              "updatemenutype": "https://test.api.guansaplc.com/api/menutype/updatemenutype",
              "getmenutype": "https://test.api.guansaplc.com/api/menutype/getmenutype",
              "getmenutypes": "https://test.api.guansaplc.com/api/menutype/getmenutypes",

              "createcatagory": "https://test.api.guansaplc.com/api/catagory/createcatagory",
              "updatecatagory": "https://test.api.guansaplc.com/api/catagory/updatecatagory",
              "getcatgorys": "https://test.api.guansaplc.com/api/catagory/getcatgorys",
              "getcatgory": "https://test.api.guansaplc.com/api/catagory/getcatgory",

              "createmenu": "https://test.api.guansaplc.com/api/menu/createmenu",
              "updatemenu": "https://test.api.guansaplc.com/api/menu/updatemenu",
              "getmenus": "https://test.api.guansaplc.com/api/menu/getmenus",
              "getmenu": "https://test.api.guansaplc.com/api/menu/getmenu",

              "createtable": "https://test.api.guansaplc.com/api/table/createtable",
              "updatetable": "https://test.api.guansaplc.com/api/table/updatetable",
              "gettables": "https://test.api.guansaplc.com/api/table/gettables",
              "gettable": "https://test.api.guansaplc.com/api/table/gettable",

              "togglemenucat": "https://test.api.guansaplc.com/api/menucatagory/togglemenucat",
              "getmenucat": "https://test.api.guansaplc.com/api/menucatagory/getmenucat",
              "getmenucat_for_order": "https://test.api.guansaplc.com/api/menucatagory/getmenucat_for_order",

              "createorder": "https://test.api.guansaplc.com/api/order/createorder",
              "updateorder": "https://test.api.guansaplc.com/api/order/updateorder",
              "deleteorder": "https://test.api.guansaplc.com/api/order/deleteorder",
              "getorder": "https://test.api.guansaplc.com/api/order/getorder",
              "getorders": "https://test.api.guansaplc.com/api/order/getorders",
              "getstatuitems": "https://test.api.guansaplc.com/api/order/getstatuitems",
              "getorderlogs": "https://test.api.guansaplc.com/api/order/getorderlogs",
              "getordersforprocesschefe": "https://test.api.guansaplc.com/api/order/getordersforprocesschefe",
              "getordersforprocessbeve": "https://test.api.guansaplc.com/api/order/getordersforprocessbeve",
              "toggleproccessnext": "https://test.api.guansaplc.com/api/order/toggleproccessnext",
              "gettableforfinance": "https://test.api.guansaplc.com/api/order/gettableforfinance",
              "tofinance": "https://test.api.guansaplc.com/api/order/tofinance",
              "getordersforfinance": "https://test.api.guansaplc.com/api/order/getordersforfinance",
              "gettableforpayment": "https://test.api.guansaplc.com/api/order/gettableforpayment",
              "orderpayment": "https://test.api.guansaplc.com/api/order/orderpayment",
              "getpaymentdetbytable": "https://test.api.guansaplc.com/api/order/getpaymentdetbytable",
              "gettablebyuser": "https://test.api.guansaplc.com/api/order/gettablebyuser",

              "get_purchase_req": "https://test.api.guansaplc.com/api/stockin/get_purchase_req",
              "get_single_purchase_req": "https://test.api.guansaplc.com/api/stockin/get_single_purchase_req",
              "create_purchase_order": "https://test.api.guansaplc.com/api/stockin/create_purchase_order",
              "edit_purchase_order": "https://test.api.guansaplc.com/api/stockin/edit_purchase_order",
              "delete_purchase_order": "https://test.api.guansaplc.com/api/stockin/delete_purchase_order",
              "approve_reject_purchase_order": "https://test.api.guansaplc.com/api/stockin/approve_reject_purchase_order",

              "get_purchase_req_approved": "https://test.api.guansaplc.com/api/stockin/get_purchase_req_approved",
              "get_single_purchase_req_approved": "https://test.api.guansaplc.com/api/stockin/get_single_purchase_req_approved",
              "purchase_purchased_not_purchase": "https://test.api.guansaplc.com/api/stockin/purchase_purchased_not_purchase",
              "get_purchase_req_reject": "https://test.api.guansaplc.com/api/stockin/get_purchase_req_reject",
              "get_single_purchase_req_reject": "https://test.api.guansaplc.com/api/stockin/get_single_purchase_req_reject",
              "get_purchase_purchased": "https://test.api.guansaplc.com/api/stockin/get_purchase_purchased",
              "get_single_purchase_purchased": "https://test.api.guansaplc.com/api/stockin/get_single_purchase_purchased",
              "approve_reject_purchase_purchased_order": "https://test.api.guansaplc.com/api/stockin/approve_reject_purchase_purchased_order",
              "get_purchase_not_purchased": "https://test.api.guansaplc.com/api/stockin/get_purchase_not_purchased",
              "get_single_not_purchase_purchased": "https://test.api.guansaplc.com/api/stockin/get_single_not_purchase_purchased",
              "get_purchase_purchased_approved": "https://test.api.guansaplc.com/api/stockin/get_purchase_purchased_approved",
              "get_single_purchase_purchased_approved": "https://test.api.guansaplc.com/api/stockin/get_single_purchase_purchased_approved",
              "purchase_recived": "https://test.api.guansaplc.com/api/stockin/purchase_recived",
              "get_purchase_received": "https://test.api.guansaplc.com/api/stockin/get_purchase_received",

              "get_store_out_req": "https://test.api.guansaplc.com/api/stockout/get_store_out_req",
              "get_single_store_out_req": "https://test.api.guansaplc.com/api/stockout/get_single_store_out_req",
              "create_stockout_request_order": "https://test.api.guansaplc.com/api/stockout/create_stockout_request_order",
              "edit_stockout_request_order": "https://test.api.guansaplc.com/api/stockout/edit_stockout_request_order",
              "delete_stockout_request_order": "https://test.api.guansaplc.com/api/stockout/delete_stockout_request_order",
              "approve_reject_stockout_request": "https://test.api.guansaplc.com/api/stockout/approve_reject_stockout_request",
              "get_stock_out_approved": "https://test.api.guansaplc.com/api/stockout/get_stock_out_approved",
              "get_single_stock_out_approved": "https://test.api.guansaplc.com/api/stockout/get_single_stock_out_approved",
              "submitted_stockout_request": "https://test.api.guansaplc.com/api/stockout/submitted_stockout_request",
              "get_stock_out_rejected": "https://test.api.guansaplc.com/api/stockout/get_stock_out_rejected",
              "get_single_stock_out_rejected": "https://test.api.guansaplc.com/api/stockout/get_single_stock_out_rejected",
              "get_stock_out_submitted": "https://test.api.guansaplc.com/api/stockout/get_stock_out_submitted",
              "get_single_stock_out_submitted": "https://test.api.guansaplc.com/api/stockout/get_single_stock_out_submitted",
              "received_stockout_request": "https://test.api.guansaplc.com/api/stockout/received_stockout_request",
              "get_stock_out_received": "https://test.api.guansaplc.com/api/stockout/get_stock_out_received",
              "get_stock_wastage_manage": "https://test.api.guansaplc.com/api/stockwastage/get_stock_wastage_manage",
              "get_single_stock_wastage_manage": "https://test.api.guansaplc.com/api/stockwastage/get_single_stock_wastage_manage",
              "create_stock_wastage": "https://test.api.guansaplc.com/api/stockwastage/create_stock_wastage",
              "get_store_wastage_req": "https://test.api.guansaplc.com/api/stockwastage/get_store_wastage_req",
              "get_store_single_wastage_req": "https://test.api.guansaplc.com/api/stockwastage/get_store_single_wastage_req",
              "edit_stock_wastage": "https://test.api.guansaplc.com/api/stockwastage/edit_stock_wastage",
              "delete_stock_wastage": "https://test.api.guansaplc.com/api/stockwastage/delete_stock_wastage",
              "approve_reject_stock_wastage_request": "https://test.api.guansaplc.com/api/stockwastage/approve_reject_stock_wastage_request",
              "get_stock_wastage_approved": "https://test.api.guansaplc.com/api/stockwastage/get_stock_wastage_approved",
              "get_stock_single_wastage_approved": "https://test.api.guansaplc.com/api/stockwastage/get_stock_single_wastage_approved",
              "get_stock_wastage_reject": "https://test.api.guansaplc.com/api/stockwastage/get_stock_wastage_reject",
              "get_stock_single_wastage_reject": "https://test.api.guansaplc.com/api/stockwastage/get_stock_single_wastage_reject",
              "report_daily_sale": "https://test.api.guansaplc.com/api/departmentuser/report_daily_sale",
              "report_daily_sale_2": "https://test.api.guansaplc.com/api/departmentuser/report_daily_sale_2",
              "r_order_for_chafe": "https://test.api.guansaplc.com/api/departmentuser/r_order_for_chafe",
              "r_order_for_waiter": "https://test.api.guansaplc.com/api/departmentuser/r_order_for_waiter",
              "r_order_for_barman": "https://test.api.guansaplc.com/api/departmentuser/r_order_for_barman",
              "report_order_by": "https://test.api.guansaplc.com/api/departmentuser/report_order_by",
              "report_served_by": "https://test.api.guansaplc.com/api/departmentuser/report_served_by",


              "redirecttologin": "https://test.guansaplc.com/login",
              "redirect": "https://test.guansaplc.com",
    */


            "urlwelcome": "/",
            "urlsiunit": "/siunit",
            "urldepartment": "/department",
            "urlemployee": "/employee",
            "urlstockitem": "/stockitem",
            "urlstockin": "/stockin",
            "urlstockout": "/stockout",
            "urlstockwastage": "/stockwastage",

            "urlmenutype": "/menutype",
            "urlcatagory": "/catagory",
            "urlmenulink": "/menu",
            "urlmenumatagory": "/menumatagory",

            "urlrstockin": "/rstockin",
            "urlrstockout": "/rstockout",
            "urlrstockwastage": "/rstockwastage",
            "urlrstockitem": "/rstockitem",
            "urlrorder": "/rorder",
            "urlrfinance": "/rfinance",
            "urluser": "/user",
            "urlobjuser": "/objuser",
            "useraccess1": "/useraccess1",
            "useraccess2": "/useraccess2",
            "urlusertable": "/usertable",


            "urlorder": "/order",
            "urltable": "/table",
            "urlchefe": "/chefe",
            "urlfinance": "/finance",
            "urlbeverage": "/beverage",

            "urlpurchase_req": "/purchase_req",
            "urlpurchase_req_approved": "/purchase_req_approved",
            "urlpurchase_req_reject": "/purchase_req_reject",
            "urlpurchase_purchased": "/purchase_purchased",
            "urlpurchase_not_purchased": "/purchase_not_purchased",
            "urlpurchase_purchased_approved": "/purchase_purchased_approved",
            "urlpurchase_received": "/purchase_received",
            "urlstock_out_req": "/stock_out_req",
            "urlstock_out_req_approved": "/stock_out_req_approved",
            "urlstock_out_req_reject": "/stock_out_req_reject",
            "urlstock_out_submitted": "/stock_out_submitted",
            "urlstock_out_received": "/stock_out_received",
            "urlstock_wastage_manage": "/stock_wastage_manage",
            "urlstock_wastage_req": "/stock_wastage_req",
            "urlstock_wastage_req_approved": "/stock_wastage_req_approved",
            "urlstock_wastage_req_reject": "/stock_wastage_req_reject",
            "urlr_daily_sales": "/r_daily_sales",
            "urlr_daily_sales_2": "/r_daily_sales_2",
            "urlr_order_for_waiter": "/r_order_for_waiter",
            "urlr_order_for_chafe": "/r_order_for_chafe",
            "urlr_order_for_barman": "/r_order_for_barman",
            "urlr_order_by": "/r_order_by",
            "urlr_served_by": "/r_served_by",
            "urlusertime": "/usertime",
            "urlauth_2": "/auth_2",
            "urlreport_stock_balance": "/report_stock_balance",
            "urlrstockinsum": "/rstockinsum",
            "urlrstockoutsum": "/rstockoutsum",
            "urld_daily_activity": "/d_daily_activity",
            "urld_yesterday_activity": "/d_yesterday_activity",
            "urlr_waiting_time": "/r_waiting_time",
            "urlr_notcom_order": "/r_notcom_order",
            "urlr_removed_order": "/r_removed_order",
            "urlordersforscreen": "/ordersforscreen",
    }
export default myconfig;