import {Component} from 'react';
import axios from "axios"
import approval from "../../img/approval.jpg"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import StockitemSelect from "../Select/StockitemSelect";

let source = axios.CancelToken.source();

class Stock_wastage_req_reject extends Component {

    constructor() {
        super();
        var date = new Date();
        this.state = {
            stock: 'nav-item',
            objs: [],
            departments: [],
            stock_item_obj:null,
            stock_item_obj_load:false,
            objsload: false,
            //modal-2
            cushow2: false,
            quantity_approved:'',
            remark_approve_requested:'',
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        this.setState({
            objs: [],
            departments: [],
            stock_item_obj:null,
            stock_item_obj_load:false,
            modal_type:'',
            objsload: false,
            cushow2: false,
            quantity_approved:'',
            remark_approve_requested:'',
        })
        let baseurl = myconfig.get_stock_wastage_reject;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            st: this.state.st,
            ed: this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu2 = (event, modal_type,id = -1) => {
        event.preventDefault()
        let baseurl = myconfig.get_stock_single_wastage_reject;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            id: id,
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        cushow2: true,
                        stock_item_obj: res.data.data,
                        quantity_approved: res.data.data.quantity_approved,
                        remark_approve_requested: res.data.data.remark_approve_requested,
                        stock_item_obj_load: true,
                        id: id,
                        modal_type: modal_type,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hidecu2 = () => {
        this.setState({
            cushow2: false,
        })
    }
    modalcu2 = () => {
        if (this.state.stock_item_obj_load) {
            let header = "Wastage Approved";
            let quantity_approved_disabled = false;
            let remark_approve_requested_disabled = false;
            let labelremark = "Remark Approved";
            let btnexe = <a href="#" onClick={event => this.submitcu2(event,true)}
                            className="btn btn-primary btn-block"><b>Wastage Approved</b></a>;
            if (this.state.modal_type == "reject") {
                header = "Wastage Reject";
                labelremark = "Remark Reject";
                quantity_approved_disabled = true;
                remark_approve_requested_disabled = false;
                btnexe = <a href="#" onClick={event => this.submitcu2(event, false)}
                            className="btn btn-danger btn-block"><b>Wastage Reject</b></a>;
            }
            return (
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.cushow2}
                    onHide={this.hidecu2}>
                    <Modal.Header><h4>{header}</h4></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <img className="profile-user-img img-fluid img-circle" src={approval}
                                                 alt="User profile picture"/>
                                        </div>
                                        <h3 className="profile-username text-center">{this.state.stock_item_obj.name_en}</h3>
                                        <p className="text-muted text-center">{this.state.stock_item_obj.name_am}</p>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            <li className="list-group-item">
                                                <b>Stock Type</b> <a
                                                className="float-right">{this.state.stock_item_obj.type}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Stock SI Unit</b> <a
                                                className="float-right">{this.state.stock_item_obj.siunit}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Department</b> <a
                                                className="float-right">{this.state.stock_item_obj.dep_name}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Quantity Requested</b> <a
                                                className="float-right">{this.state.stock_item_obj.quantity_requested}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Quantity Approved</b> <a
                                                className="float-right">{this.state.stock_item_obj.quantity_approved}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Requested By</b> <a
                                                className="float-right">{this.state.stock_item_obj.requested_by}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Approved By</b> <a
                                                className="float-right">{this.state.stock_item_obj.approved_requested_by}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Requested Date</b> <a
                                                className="float-right">{this.state.stock_item_obj.requested_date}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Approved Date</b> <a
                                                className="float-right">{this.state.stock_item_obj.approved_requested_date}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Remark Requested</b> <a
                                                className="float-right">{this.state.stock_item_obj.remark_requested}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Remark Approved</b> <a
                                                className="float-right">{this.state.stock_item_obj.remark_approve_requested}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Status</b> <a
                                                className="float-right">{this.state.stock_item_obj.status}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Stock Out Approved Quantity</b> <a
                                                className="float-right">{this.state.stock_item_obj.out_qty_approved}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Stock Out Requested By</b> <a
                                                className="float-right">{this.state.stock_item_obj.requested_by}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Stock Out Approved Req By</b> <a
                                                className="float-right">{this.state.stock_item_obj.out_approved_requested_by}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Approved Req Date</b> <a
                                                className="float-right">{this.state.stock_item_obj.out_approved_requested_date}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Stock out Date</b> <a
                                                className="float-right">{this.state.stock_item_obj.out_received_date}</a>
                                            </li>
                                        </ul>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Quantity Approved</label>
                                                <input disabled={quantity_approved_disabled} className="form-control"
                                                       type="text" name="quantity_approved" onChange={this.inputmgt}
                                                       value={this.state.quantity_approved}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>{labelremark}</label>
                                                <textarea disabled={remark_approve_requested_disabled} rows="5"
                                                          className="form-control" type="text" name="remark_approve_requested"
                                                          onChange={this.inputmgt}>
                                                    {this.state.remark_approve_requested}
                                            </textarea>
                                            </div>
                                        </div>
                                        {btnexe}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }
    }
    submitcu2 = (event,isapproved=false) => {
        event.preventDefault()
        let baseurl = myconfig.approve_reject_stock_wastage_request;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            quantity_approved: this.state.quantity_approved,
            remark_approve_requested: this.state.remark_approve_requested_disabled,
            isapproved: isapproved,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata = () => {
        let retdata = null;
        let appr = null;
        if (this.state.objsload) {
            let count = 0;
            retdata = this.state.objs.datas.map(value => {
                count = count + 1
                if (this.state.objs.usermatrix.approval) {
                    appr =
                        (
                            <td><a href="#" onClick={(event => this.showcu2(event, "approve",value.id))}>Approved</a></td>
                        )
                }
                return (<tr>
                    <td>{count}</td>
                    {appr}
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.type}</td>
                    <td>{value.siunit}</td>
                    <td>{value.dep_name}</td>
                    <td>{value.quantity_requested}</td>
                    <td>{value.quantity_approved}</td>
                    <td>{value.approved_requested_by}</td>
                    <td>{value.requested_by}</td>
                    <td>{value.requested_date}</td>
                    <td>{value.approved_requested_date}</td>
                    <td>{value.remark_requested}</td>
                    <td>{value.remark_approve_requested}</td>
                    <td>{value.status}</td>
                    <td>{value.out_qty_approved}</td>
                    <td>{value.out_approved_requested_by}</td>
                    <td>{value.requested_by}</td>
                    <td>{value.approved_requested_date}</td>
                    <td>{value.out_received_date}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.cat_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }


    render() {
        let appr = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.approval) {
                appr =
                    (
                        <th width="11.1%">Approved</th>
                    )
            }
        }
        return (
            <>
                <Nav type="stock_wastage_req_reject"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock-wastage reject</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Stock-wastage reject</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    {appr}
                                                    <th>Stock English Name</th>
                                                    <th>Stock Amharic Name</th>
                                                    <th>Stock Type</th>
                                                    <th>Stock SI Unit</th>
                                                    <th>Department</th>
                                                    <th>Quantity Request</th>
                                                    <th>Quantity Approved</th>
                                                    <th>Requested By</th>
                                                    <th>Approved By</th>
                                                    <th>Request Date</th>
                                                    <th>Approved Date</th>
                                                    <th>Wastage Remark</th>
                                                    <th>Approved Remark</th>
                                                    <th>Status</th>
                                                    <th>Stock-out quantity</th>
                                                    <th>Stock-out By</th>
                                                    <th>Stock-out Approved By</th>
                                                    <th>Stock-out Approved Date</th>
                                                    <th>Stock-out Received Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu2()}
            </>
        )
    }
}
export default Stock_wastage_req_reject;
