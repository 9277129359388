import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';
import SiunitSelect from "./Select/SiunitSelect";

let source = axios.CancelToken.source();

class Stockitem extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            filtered: [],
            txtsearch:'',

            //modal
            cushow:false,
            iscreate:true,
            name_en:'',
            name_am:'',
            wastagealert:'',
            shortagealert:'',
            type:'Consumable',
            id:'-1',
            si_unit_id: "-1",
            siunit_obj:null,
        }
    }
    componentDidMount() {
        this.fetcheddata();
    }
    successumit=()=> {
        this.setState({
            siunit: '',
            cushow: false,
        }, () => this.fetcheddata())
    }
    fetcheddata = () => {
        let baseurl = myconfig.stockitemgetstockitems;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered: res.data.datas,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a> |<a href="#">Delete</a></td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.type}</td>
                    <td>{value.siunit}</td>
                    <td>{value.wastagealert}</td>
                    <td>{value.shortagealert}</td>


                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgtsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name_en.toUpperCase().includes(value.toUpperCase())||
                    v.name_am.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            filtered: temp,
            [name]: value,
        })
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow:true,
                iscreate:iscreate,
                name_en:'',
                name_am:'',
                wastagealert:'',
                shortagealert:'',
                type:'Consumable',
                id:'-1',
                si_unit_id: "-1",
                siunit_obj:null,
            })
        }
        else {
            let baseurl = myconfig.stockitemgetstockitem;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    /*  window.localStorage.setItem("fas_sid", null);
                      window.localStorage.setItem("fas_username", null);
                      window.localStorage.setItem("fas_name", null);
                      var win = window.open(redirecttologin, "_self");*/
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            cushow: true,
                            iscreate: iscreate,
                            id: id,
                            si_unit_id: res.data.data.si_id,
                            siunit_obj:res.data.data,
                            name_en:res.data.data.name_en,
                            name_am:res.data.data.name_am,
                            type:res.data.data.type,
                            wastagealert:res.data.data.wastagealert,
                            shortagealert:res.data.data.shortagealert,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Stock Item</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">

                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Item Name (En)</label>
                                                <input  className="form-control" type="text" name="name_en" onChange={this.inputmgt} value={this.state.name_en}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Item Name (Amh)</label>
                                                <input  className="form-control" type="text" name="name_am" onChange={this.inputmgt} value={this.state.name_am}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <SiunitSelect siunit_obj={this.state.siunit_obj} siunit_selected={this.siunit_selected}/>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <select  className="form-control" name="type" onChange={this.inputmgt} value={this.state.type}>
                                                    <option value="Consumable">Consumable</option>
                                                    <option value="Not-consumable">Not-consumable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Wastage Alert</label>
                                                <input  className="form-control" type="text" name="wastagealert" onChange={this.inputmgt} value={this.state.wastagealert}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Shortage Alert</label>
                                                <input  className="form-control" type="text" name="shortagealert" onChange={this.inputmgt} value={this.state.shortagealert}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.stockitemupdate;
        if (this.state.iscreate)
            baseurl = myconfig.stockitemcreate
        axios.post(baseurl, {
            id: this.state.id,
            name_en: this.state.name_en,
            name_am: this.state.name_am,
            si_unit_id: this.state.si_unit_id,
            type: this.state.type,
            wastagealert: this.state.wastagealert,
            shortagealert: this.state.shortagealert,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.successumit()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    siunit_selected=(id)=> {
        this.setState({
            si_unit_id: id,
        },()=>{console.log(this.state)})
    }
    render() {
        return (
            <>
                <Nav type="stockitem"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Item Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Stock Item</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch" onChange={this.inputmgtsearch}
                                                           value={this.state.txtsearch}  className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New Item</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Name(EN)</th>
                                                    <th>Name(AMH)</th>
                                                    <th>Type</th>
                                                    <th>Unit</th>
                                                    <th>Wastage Alert</th>
                                                    <th>Shortage Alert</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Stockitem;
