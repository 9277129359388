import {Component} from 'react';
import axios from "axios"
import approval from "../../img/approval.jpg"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import StockitemSelect from "../Select/StockitemSelect";
import StockitemSelect_Pur from "../Select/StockitemSelect_Pur";

let source = axios.CancelToken.source();

class Purchase_req extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            cushow: false,
            iscreate: true,

            id: '-1',
            stock_item_id: '-1',
            quantity_requested: '',
            remark_requested: '',
            stock_item_obj: null,
            modal_type: '',

            quantity_approved:'',
            remark_approve_requested:'',
        }
    }

    stockitem_selected = (id) => {
        this.setState({
            stock_item_id: id,
        }, () => {
            console.log(this.state)
        })
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            //modal
            cushow: false,
            iscreate: true,

            id: '-1',
            stock_item_id: '-1',
            quantity_requested: '',
            remark_requested: '',
            stock_item_obj: null,
            modal_type: '',
        })
        let baseurl = myconfig.get_purchase_req;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
              /*  window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data)
                this.setState({
                    objs: res.data,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }


    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event, iscreate, id = -1) => {
        event.preventDefault()
        if (iscreate) {
            this.setState({
                cushow: true,
                iscreate: iscreate,
                stock_item_id: '-1',
                quantity_requested: '',
                remark_requested: '',
                stock_item_obj: null,
                modal_type: 'create',
            })
        } else {
            let baseurl = myconfig.get_single_purchase_req;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                id: id,
                sid: window.localStorage.getItem("mycafe_sid"),
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("mycafe_sid", null);
                    window.localStorage.setItem("mycafe_username", null);
                    window.localStorage.setItem("mycafe_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        console.log(res.data)
                        this.setState({
                            cushow: true,
                            id: id,
                            iscreate: iscreate,
                            stock_item_id: res.data.data.stock_item_id,
                            quantity_requested: res.data.data.quantity_requested,
                            remark_requested: res.data.data.remark_requested,
                            stock_item_obj: res.data.data,
                            modal_type: 'update',
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    showdelete = (event, id = -1) => {
        event.preventDefault()
        let baseurl = myconfig.get_single_purchase_req;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            id: id,
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    console.log(res.data)
                    this.setState({
                        cushow: true,
                        id: id,
                        stock_item_id: res.data.data.stock_item_id,
                        quantity_requested: res.data.data.quantity_requested,
                        remark_requested: res.data.data.remark_requested,
                        stock_item_obj: res.data.data,
                        modal_type: 'delete',
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    showapproved = (event, isapproved, id = -1) => {
        event.preventDefault()
        this.setState({
            quantity_approved: '',
            remark_approve_requested: '',
        })
        let baseurl = myconfig.get_single_purchase_req;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            id: id,
            sid: window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
             /*   window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let app = "approved"
                    if (!isapproved) {
                        app = "reject"
                    }
                    this.setState({
                        cushow: true,
                        id: id,
                        stock_item_id: res.data.data.stock_item_id,
                        quantity_requested: res.data.data.quantity_requested,
                        remark_requested: res.data.data.remark_requested,
                        stock_item_obj: res.data.data,
                        modal_type: app,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hidecu = () => {
        this.setState({
            cushow: false,
        })
    }
    modal_body_req=()=> {
        let header = "Purchase Order Request Create"
        let stockitemdisabled = false;
        let quantity_requested_disabled = false;
        let remark_requested_disabled = false;
        let button_execute = <button className="btn btn-outline-primary" onClick={this.submitcu}> Create Purchase
            Order </button>
        if (this.state.modal_type == "update") {
            header = "Purchase Order Request Update"
            button_execute =
                <button className="btn btn-outline-primary" onClick={this.submitcu}> Update Purchase Order </button>
        } else if (this.state.modal_type == "delete") {
            header = "Purchase Order Request Delete"
            stockitemdisabled = true;
            quantity_requested_disabled = true;
            remark_requested_disabled = true;
            button_execute = <button className="btn btn-outline-primary" onClick={this.submitcu_delete}> Delete Purchase
                Order </button>
        }
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>{header}</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <StockitemSelect_Pur isdisabled={stockitemdisabled} stock_item_obj={this.state.stock_item_obj}
                                             stockitem_selected={this.stockitem_selected}/>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Quantity Requested</label>
                                                <input disabled={quantity_requested_disabled} className="form-control"
                                                       type="text" name="quantity_requested" onChange={this.inputmgt}
                                                       value={this.state.quantity_requested}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Quantity Remark</label>
                                                <textarea disabled={remark_requested_disabled} rows="5"
                                                          className="form-control" type="text" name="remark_requested"
                                                          onChange={this.inputmgt}>
                                                    {this.state.remark_requested}
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                {button_execute}
                                                <button className="btn btn-outline-danger"
                                                        onClick={this.hidecu}> Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    modal_body_app=()=> {
        let disabled_qty_approved=false;
        let label_remark_approved="Approved Remark";
        let buttonexe=  <a href="#" onClick={event => this.submitcu_approval(event,1)} className="btn btn-primary btn-block"><b>Approved</b></a>;
        if(this.state.modal_type=="reject") {
            disabled_qty_approved = true;
            label_remark_approved = "Reject Remark";
            buttonexe = <a href="#" onClick={event => this.submitcu_approval(event, 0)}
                           className="btn btn-danger btn-block"><b>Reject</b></a>;
        }

        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Approved Page</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        <img className="profile-user-img img-fluid img-circle" src={approval} alt="User profile picture" />
                                    </div>
                                    <h3 className="profile-username text-center">{this.state.stock_item_obj.name_en}</h3>
                                    <p className="text-muted text-center">{this.state.stock_item_obj.name_am}</p>
                                    <p className="text-muted text-center">{this.state.stock_item_obj.siunit}</p>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        <li className="list-group-item">
                                            <b>Stock Type</b> <a className="float-right">{this.state.stock_item_obj.type}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Stock SI Unit</b> <a className="float-right">{this.state.stock_item_obj.siunit}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Quantity Requested</b> <a className="float-right">{this.state.stock_item_obj.quantity_requested}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Remark Requested</b> <a className="float-right">{this.state.stock_item_obj.remark_requested}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Request By</b> <a className="float-right">{this.state.stock_item_obj.requested_by}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Request Date</b> <a className="float-right">{this.state.stock_item_obj.requested_date}</a>
                                        </li>
                                    </ul>
                                    <div className="col-12 px-3">
                                        <div className="form-group">
                                            <label>Quantity Approved</label>
                                            <input disabled={disabled_qty_approved} className="form-control"
                                                   type="text" name="quantity_approved" onChange={this.inputmgt}
                                                   value={this.state.quantity_approved}/>
                                        </div>
                                    </div>
                                    <div className="col-12 px-3">
                                        <div className="form-group">
                                            <label>{label_remark_approved}</label>
                                            <textarea rows="5"
                                                      className="form-control" type="text" name="remark_requested"
                                                      onChange={this.inputmgt}>
                                                    {this.state.remark_approve_requested}
                                            </textarea>
                                        </div>
                                    </div>
                                    {buttonexe}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    modalcu = () => {
        if (this.state.modal_type == "approved" ||
            this.state.modal_type == "reject") {
            return <>{this.modal_body_app()}</>
        }else {
            return <>{this.modal_body_req()}</>
        }
    }
    submitcu = (event) => {
        event.preventDefault()

        let baseurl = myconfig.edit_purchase_order;
        let redirecttologin = myconfig.redirecttologin;
        if (this.state.iscreate)
            baseurl = myconfig.create_purchase_order;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            stock_item_id: this.state.stock_item_id,
            quantity_requested: this.state.quantity_requested,
            remark_requested: this.state.remark_requested,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    submitcu_delete = (event) => {
        event.preventDefault()
        let baseurl = myconfig.delete_purchase_order;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    submitcu_approval = (event,isapproved) => {
        event.preventDefault()
        let baseurl = myconfig.approve_reject_purchase_order;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("mycafe_sid"),
            id: this.state.id,
            quantity_approved: this.state.quantity_approved,
            remark_approve_requested: this.state.remark_approve_requested,
            isapproved: isapproved,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
            /*    window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata = () => {
        let retdata = null;
        if (this.state.objsload) {
            let count = 0;
            retdata = this.state.objs.datas.map(value => {
                count = count + 1
                let edit = null;
                let del = null;
                let appr = null;
                let reje = null;
                if (this.state.objs.usermatrix.update) {
                    edit =
                        (
                            <td><a href='' onClick={(event => {
                                this.showcu(event, false, value.id)
                            })}>Edit</a>
                            </td>
                        )
                }
                if (this.state.objs.usermatrix.delete) {
                    del =
                        (
                            <td><a href='' onClick={(event => {
                                this.showdelete(event, value.id)
                            })}>Delete</a>
                            </td>
                        )
                }
                if (this.state.objs.usermatrix.approval) {
                    appr =
                        (
                            <td><a href='' onClick={(event => {
                                this.showapproved(event, true, value.id)
                            })}>Approved</a>
                            </td>
                        )
                }
                if (this.state.objs.usermatrix.approval) {
                    reje =
                        (
                            <td><a href='' onClick={(event => {
                                this.showapproved(event, false, value.id)
                            })}>Reject</a>
                            </td>
                        )
                }

                return (<tr>
                    <td>{count}</td>
                    {edit}
                    {del}
                    {appr}
                    {reje}
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.siunit}</td>
                    <td>{value.quantity_requested}</td>
                    <td>{value.requested_by}</td>
                    <td>{value.requested_date}</td>
                    <td>{value.remark_requested}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.cat_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    render() {
        let header = null;
        let edit = null;
        let del = null;
        let appr = null;
        let reje = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.create) {
                header = (<div className="card-header">
                    <h3 className="card-title"></h3>
                    <div className="card-tools">
                        <div className="input-group input-group-sm" style={{width: '150px'}}>
                            <button className="btn btn-outline-primary btn-block"
                                    onClick={(event => this.showcu(event, true))}>New Purchase Order
                            </button>
                        </div>
                    </div>
                </div>)
            }
            if (this.state.objs.usermatrix.update) {
                edit =
                    (
                        <th width="11.1%">Edit</th>
                    )
            }
            if (this.state.objs.usermatrix.delete) {
                del =
                    (
                        <th width="11.1%">Delete</th>
                    )
            }
            if (this.state.objs.usermatrix.approval) {
                appr =
                    (
                        <th width="11.1%">Approved</th>
                    )
            }
            if (this.state.objs.usermatrix.approval) {
                reje =
                    (
                        <th width="11.1%">Reject</th>
                    )
            }
        }
        return (
            <>
                <Nav type="purchase_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Purchase Order</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Purchase Order</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        {header}
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    {edit}
                                                    {del}
                                                    {appr}
                                                    {reje}
                                                    <th>Name(EN)</th>
                                                    <th>Name(AM)</th>
                                                    <th>Unit</th>
                                                    <th>QTY Requested</th>
                                                    <th>Requested By</th>
                                                    <th>Requested Date</th>
                                                    <th>Remark</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Purchase_req;
