import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class ObjUser extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            obj: [],
            objload: false,

            //modal
            cushow: false,
            iscreate: true,
            id: '-1',
            object_name: '',

        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let baseurl = myconfig.getobjusers;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgtchk = (event) => {
        var name = event.target.name;
        var value = event.target.checked;
        this.setState({
            [name]: value,
        })
    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            console.log(this.state.obj.datas)
            retdata = this.state.obj.datas.map(value => {
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td>
                        <a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a> |
                        <a href="#">Delete</a></td>
                    <td>{value.object_name}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    modalcu=()=> {
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>User Object</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Object Name</label>
                                                <input  className="form-control" type="text" name="object_name" onChange={this.inputmgt} value={this.state.object_name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updateobjuser;
        if (this.state.iscreate)
            baseurl = myconfig.createobjuser
        axios.post(baseurl, {
            id: this.state.id,
            object_name: this.state.object_name,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.successumit()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow:true,
                iscreate:iscreate,
                id: '-1',
                object_name:'',
            })
        }
        else {
            let baseurl = myconfig.getobjuser;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    /*  window.localStorage.setItem("fas_sid", null);
                      window.localStorage.setItem("fas_username", null);
                      window.localStorage.setItem("fas_name", null);
                      var win = window.open(redirecttologin, "_self");*/
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            cushow: true,
                            iscreate: iscreate,
                            id: id,
                            object_name: res.data.data.object_name,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }



    successumit=()=> {
        this.setState({
            siunit: '',
            cushow: false,
        }, () => this.fetcheddata())
    }
    render() {
        return (
            <>
                <Nav type="objuser"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">User Object Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">User Object</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New User Object</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Object Name</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default ObjUser;
