import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class Table extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            filtered:[],
            objsload: false,
            id:-1,
            cushow:false,
            iscreate:true,
            txtsearch:'',

            table_name:'',
            description:'',
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            //modal
            cushow:false,
            iscreate:true,
            table_name:'',
            description:'',
            txtsearch:'',
            selectedindex:-1,
        })
        let baseurl = myconfig.gettables;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }


    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow: true,
                iscreate: iscreate,
                table_name:'',
                description:'',
            })
        }
        else {
            let baseurl = myconfig.gettable;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                id: 1,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    /*  window.localStorage.setItem("fas_sid", null);
                      window.localStorage.setItem("fas_username", null);
                      window.localStorage.setItem("fas_name", null);
                      var win = window.open(redirecttologin, "_self");*/
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            cushow: true,
                            id:id,
                            iscreate: iscreate,
                            table_name:res.data.data.table_name,
                            description:res.data.data.description,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Table Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Table</label>
                                                <input  className="form-control" type="text" name="table_name" onChange={this.inputmgt} value={this.state.table_name}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea className="form-control" rows="5"  name="description"  onChange={this.inputmgt}>{this.state.description}</textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updatetable;
        if (this.state.iscreate)
            baseurl = myconfig.createtable;
        axios.post(baseurl, {
            id: this.state.id,
            table_name:this.state.table_name,
            description:this.state.description,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a> |<a href="#">Delete</a></td>
                    <td>{value.table_name}</td>
                    <td>{value.description}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.table_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    render() {
        return (
            <>
                <Nav type="table"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Table Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Table</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right" value={this.state.txtsearch} onChange={this.inputsearch} placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New Table</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Table Name</th>
                                                    <th>Description</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Table;
