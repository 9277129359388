import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Ordersforscreen extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload:false,
        }
    }
    componentDidMount() {
        this.fetcheddata()
        setInterval(() => {
            this.fetcheddata(true)
        }, 100000)
    }
    fetcheddata = () => {
        let baseurl=myconfig.getordersforscreen;
        let redirecttologin=myconfig.redirecttologin;
        axios.post(baseurl, {
            sid:window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.objs.datas.map(value => {
                count=count+1
                let status= <span className="badge badge-success">Success</span>
                if(value.status=="Started")
                    status= <span className="badge badge-primary">Started</span>
                if(value.status=="Queued")
                    status= <span className="badge bg-warning">Queued</span>
                return (<tr>
                    <td>{count}</td>
                    <td>{value.table_name}</td>
                    <td>{value.name_am}</td>
                    <td>{value.name_en}</td>
                    <td>{value.type}</td>
                    <td>{value.quantity}</td>
                    <td>{value.order_by}</td>
                    <td>{value.status}</td>
                    <td className="project-state">
                        {status}
                    </td>
                    <td className="project_progress">
                        <div className="progress progress-sm">
                            <div className="progress-bar bg-cyan" role="progressbar" aria-valuenow={value.pre} aria-valuemin={0} aria-valuemax={100} style={{width: (value.pre+"%")}}>
                            </div>
                        </div>
                        <small>
                            {value.pre}% Complete
                        </small>
                    </td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    render() {
        return (
            <>
                <Nav type="ordersforscreen"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Order Management System</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Order</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row pt-3">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Queued Lists</h3>
                                            </div>
                                            <div className="card-body p-0">
                                                <table className="table table-striped projects">
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: '1%'}}>
                                                            #
                                                        </th>
                                                        <th style={{width: '10%'}}>
                                                            Table Name
                                                        </th>
                                                        <th style={{width: '10%'}}>
                                                           Name(EN)
                                                        </th>
                                                        <th style={{width: '10%'}}>
                                                            Name(AM)
                                                        </th>
                                                        <th style={{width: '10%'}}>
                                                           Type
                                                        </th>
                                                        <th style={{width: '5%'}}>
                                                           Quantity
                                                        </th>
                                                        <th style={{width: '10%'}}>
                                                           Order By
                                                        </th>
                                                        <th style={{width: '10%'}} className="text-center">
                                                           Status
                                                        </th>
                                                        <th>
                                                            Order progress
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.tabledata()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Ordersforscreen;
